import React, { useState, useEffect } from "react";
import { Button, Table } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Pencil, Trash } from "react-bootstrap-icons";
import Edit from "./Edit";


const Manage = () => {
  const navigate = useNavigate();
  // State variable to store your permission group data
  const [permissionGroups, setPermissionGroups] = useState([
    { id: 1, Name: "Admins", Description: "Administrator Role", CreatedOn: "2023-01-01" },
    { id: 2, Name: "Users", Description: "Regular User Role", CreatedOn: "2023-01-02" },
    // Add more data as needed
  ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Simulating an API call
        const response = await axios.get('https://raftaarapi.jithvar.com/').then((data) => {
          console.log(data);
          setPermissionGroups(data.data.Data);
        });

        // Since we don't have a real API, we'll use the dummy data directly
        setPermissionGroups(response.data.Data);

        // Simulating loading data after a delay (remove in a real implementation)
        setTimeout(() => {
          setPermissionGroups([
            { id: 1, Name: "Admins", Description: "Administrator Role", CreatedOn: "2023-01-01" },
            { id: 2, Name: "Users", Description: "Regular User Role", CreatedOn: "2023-01-02" },
            { id: 3, Name: "Guests", Description: "Guest User Role", CreatedOn: "2023-01-03" },
          ]);
        }, 1000);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  function setID(id, name, status) {
    localStorage.setItem("id", id);
    localStorage.setItem("Name", name);
    localStorage.setItem("Status", status);
    
  }

  function deletePermissionGroup(id) {
    // Filter out the item with the specified id
    const updatedPermissionGroups = permissionGroups.filter((group) => group.id !== id);
    setPermissionGroups(updatedPermissionGroups);
  }

  return (
    <div style={{ margin: "5rem" }}>
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Description</th>
            <th>Created On</th>
            <th className="action-header">Action</th>
          </tr>
        </thead>
        <tbody>
          {permissionGroups.map((group, index) => {
            return (
              <tr key={group.id}>
                <td>{index + 1}</td>
                <td>{group.Name}</td>
                <td>{group.Description}</td>
                <td>{group.CreatedOn}</td>
                <td className="action-cell">
{/*                   
                  <Link to={`/user-management/permission-groups/edit`}>
                    <Button
                      onClick={(e) => setID(group.id, group.Name, group.Description)}
                      variant="info"
                    >
                      Edit
                    </Button>
                  </Link>{" "}
                  {/* Add a space between buttons */}
                  {/* <Button onClick={(e) => deletePermissionGroup(group.id)} variant="danger">
                    Delete
                  </Button>  */}
                 {/* <Link to='/user-management/roles/edit'> */}
                          {/* <button type="button" 
                           onClick={(e) => setID(group.id, group.Name, group.Description)}
                           className="btn btn"  data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" >
                            <Pencil color="royalblue" size={16} data-toggle="tooltip" title="Edit" />
                          </button> */}
                          <button  onClick={(e) => setID(group.id, group.Name, group.Description)}
                           class="btn btn" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasExample">
                          <Pencil color="royalblue" size={16} data-toggle="tooltip" title="Edit" />
                          </button>
                          {/* <button className="btn btn-primary btn-round btn-sm" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">Update </button> */}
                             {/* </Link> */}
                    
                        <button type="button" className="btn btn" onClick={(e) => deletePermissionGroup(group.id)}>
                          <Trash color="royalblue" size={16} data-toggle="tooltip" title="Delete" />
                        </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>


      <div className="offcanvas offcanvasbody offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasExampleLabel">
  <div className="offcanvas-header ">
    <h5 className="offcanvas-title" id="offcanvasExampleLabel"></h5>
    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div className="offcanvas-body">
    <div>
     <Edit/>
    </div>
  </div>
</div>
      {/* <Link className="d-grid gap-2" to="/permission-groups/create">
        <Button variant="warning">Create</Button>
      </Link> */}
    </div>
  );
};

export default Manage;
