
import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useForm } from "react-hook-form";
import array from "./array";
import { Link, redirect } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../../../../config";
import { toast } from "react-toastify";
// import config from "../../../config";

function Edit() {
	const pageTitle = "Edit Role"
    const navigate = useNavigate();
    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm();
    // const [selectedDate, setSelectedDate] = useState(null); 
    const userData = sessionStorage.getItem("user");
    // const user = userData ? JSON.parse(userData) : null;
  
    const onSubmit = (data) => {
      // data.project_date = selectedDate;
      // data.created_by = user.name;
      // data.status = "active";
      axios.put(`${config.apiUrl}/section/update/`, )  //slug needed here 
        .then(response => {
          // console.log('Work added successfully:', response.data);
          toast.success("Update successfully");
          navigate('/section/manage');
          // Clear the form or show a success message as needed
        })
        .catch(error => {
          console.error('Error adding update:', error);
          // Handle error, show error message, etc.
        });
      toast.success("Update successfully");
      redirect("/section/manage")
    };  
	return (
<>
		  <div className=" col-md-12 m-auto">
            <div className="card-header">
              <h3 className="text-bold">{pageTitle}</h3>
            </div>
            <p></p>
            <div className="">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row mb-15">
                  <label
                    htmlFor="type"
                    className="col-sm-4 col-form-label text-right"
                  >
                    {" "}
                     Name
                  </label>
                  <div className="col-lg-8">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      {...register("name", { required: true })}
                    />
                    {errors.name && (
                      <span className="text-danger">
                         Name Is Required.
                      </span>
                    )}
                  </div>
                </div>

                {}

                <div className="row mb-15">
                  <label
                    htmlFor="type"
                    className="col-sm-4 col-form-label text-right"
                  >
                    Status
                  </label>
                  <div className="col-lg-8">
                    <select
                      name="status"
                      {...register("type")}
                      className="form-control"
                    >
                      <option value={"Active"}>Active</option>
                      <option value={"Deactive"}>Deactive</option>
                    </select>
                  </div>
                </div>

                <div className="row mb-15">
                  <div className="col-sm-12 text-right">
                    <button
                      className="btn btn-primary btn-round mr-5"
                      type="submit"
                    >
                      Update
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
</>
	);
}

export default Edit;
