import React, { useState } from "react";
import { Badge, Button } from "react-bootstrap";
import { convertTimestamp } from "../../../common/utils/convertor/dateTime";
import useStoreHook from "../hooks/store.hook";

const StoredItem = ({ data }) => {
  const [isPage, setIsPage] = useState(false);
  const { onDelete, onUpdate } = useStoreHook();
  const [pageView, setPageView] = useState(data?.page_view || "");
  const handleCollectionDelete = (id) => {
    onDelete(id);
  };

  const handleCollectionUpdate = (value) => {
    const updateData = { id: value?._id, page_view: pageView };
    onUpdate(updateData);
    setIsPage(!isPage);
  };

  return (
    <>
      <div className="border px-15 py-10 bg-white">
        <div className="d-flex gap-2 flex-column">
          <div className="d-flex justify-content-between align-items-center">
            <Badge className="bg-secondary text-capitalize">
              {data?.type ?? "default"}
            </Badge>
            <i
              className="fa fa-star fs-4 cursor-pointer"
              onClick={() => handleCollectionDelete(data?._id)}
            />
          </div>
          <div>
            <p>{data?.title ?? "..."}</p>
          </div>
          <div className="d-flex gap-3 border-bottom pb-1">
            <span className="d-flex gap-2 align-items-center">
              <i className="fa fa-user-o fs-5" />
              <span>{data?.created_by ?? "..."}</span>
            </span>
            <span className="d-flex gap-2 align-items-center">
              <i className="fa fa-clock-o fs-5" />
              <span>{convertTimestamp(data?.created_on) ?? "..."}</span>
            </span>
          </div>
          <div className="d-flex gap-3 align-items-center justify-content-between">
            <div className="d-flex gap-2">
              <span className="d-flex gap-2 align-items-center">
                <i className="fa  fa-bar-chart fs-5" />
                <span>0</span>
              </span>
              {Number(data?.page_view) > 0 && (
                <span className="d-flex gap-2 align-items-center">
                  <i className="fa  fa-globe fs-5" />
                  <span>{data?.page_view ?? "0"}</span>
                </span>
              )}
            </div>
            <p
              className="p-0 m-0 btn text-primary"
              onClick={() => setIsPage(!isPage)}
              role="button"
            >
              Mange
            </p>
          </div>
          {isPage && (
            <div>
              <label className="fw-bolder">Set Page views </label>
              <input
                type="number"
                className="form-control"
                name="page_view"
                placeholder="Page view"
                value={pageView}
                onChange={(e) => setPageView(e.target.value)}
              />
              <div className="pt-4 d-flex gap-3 justify-content-end text-white">
                <Button
                  className="  shadow-none rounded"
                  onClick={() => handleCollectionUpdate(data)}
                >
                  Update
                </Button>
                <Button
                  className="border-danger bg-danger shadow-none rounded"
                  onClick={() => setIsPage(!isPage)}
                >
                  Cancle
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default StoredItem;
