import React, { useEffect } from "react";
import { ConvertArrayTOString } from "../../common/utils/arrayToString";

function TagsCreate(props) {
  const {
    onSubmit,
    onUpdate,
    register,
    handleSubmit,
    errors,
    sectionList,
    sectionItem,
    setValue,
    show,
    setShow,
    generateSlug,
  } = props;

  const pageTitle = sectionItem?._id ? "Update Tag" : "Create Tag";
  useEffect(() => {
    setValue("name", sectionItem?.name);
    setValue("slug", sectionItem?.slug);
    setValue("meta_title", sectionItem?.meta_title);
    setValue("meta_description", sectionItem?.meta_description);
  }, [sectionItem]);
  return (
    <>
      <div className=" col-md-12 m-auto">
        <div className="card-header">
          <h3 className="text-bold text-uppercase">{pageTitle}</h3>
        </div>
        <p></p>
        <div className="">
          <form
            onSubmit={
              sectionItem?._id ? handleSubmit(onUpdate) : handleSubmit(onSubmit)
            }
          >
            <div className="row mb-15">
              <label
                htmlFor="type"
                className="col-sm-4 col-form-label text-right"
              >
                Name
              </label>
              <div className="col-lg-8">
                <input
                  type="text"
                  name="name"
                  {...register("name", { required: true })}
                  onChange={(e) => {
                    generateSlug(e);
                    e?.preventDefault();
                  }}
                  className="form-control"
                />
                {errors.name && (
                  <span className="text-danger"> Name is required.</span>
                )}
              </div>
            </div>
            <div className="row mb-15">
              <label
                htmlFor="slug"
                className="col-sm-4 col-form-label text-right"
              >
                Slug
              </label>
              <div className="col-lg-8">
                <input
                  type="text"
                  name="slug"
                  className="form-control"
                  {...register("slug", { required: true })}
                />
                {errors.slug && (
                  <span className="text-danger">Slug is required.</span>
                )}
              </div>
            </div>
            <div className="row mb-15">
              <label
                htmlFor="type"
                className="col-sm-4 col-form-label text-right"
              >
                Page Title
              </label>
              <div className="col-lg-8">
                <input
                  type="text"
                  name="page"
                  {...register("page", { required: true })}
                  className="form-control"
                />
                {errors.name && (
                  <span className="text-danger"> Page is required.</span>
                )}
              </div>
            </div>
            <div className="row mb-15">
              <label
                htmlFor="type"
                className="col-sm-4 col-form-label text-right"
              >
                Keyword
              </label>
              <div className="col-lg-8">
                <input
                  type="text"
                  name="keyword"
                  {...register("keyword", { required: true })}
                 
                  className="form-control"
                />
                {errors.name && (
                  <span className="text-danger"> Keyword is required.</span>
                )}
              </div>
            </div>
            <div className="row mb-15">
              <label
                htmlFor="meta_title"
                className="col-sm-4 col-form-label text-right"
              >
                Meta Title
              </label>
              <div className="col-lg-8">
                <input
                  type="text"
                  name="meta_title"
                  className="form-control"
                  {...register("meta_title", { required: true })}
                />
                {errors.meta_title && (
                  <span className="text-danger">Meta title is required.</span>
                )}
              </div>
            </div>
            <div className="row mb-15">
              <label
                htmlFor="display_name"
                className="col-sm-4 col-form-label text-right"
              >
                Meta Description
              </label>
              <div className="col-lg-8">
                <input
                  type="text"
                  name="meta_description"
                  className="form-control"
                  {...register("meta_description", { required: true })}
                />
                {errors.meta_description && (
                  <span className="text-danger">
                    Meta Description is required.
                  </span>
                )}
              </div>
            </div>

            <div className="row mb-15">
              <label
                htmlFor="status"
                className="col-sm-4 col-form-label text-right"
              >
                Status
              </label>
              <div className="col-lg-8">
                <select
                  name="status"
                  {...register("status")}
                  defaultValue={"Active"}
                  className="form-control"
                >
                  <option value={"Active"}>Active</option>
                  <option value={"Inactive"}>Inactive</option>
                </select>
              </div>
            </div>

            <div className="row mb-15">
              <div className="col-sm-12 text-right">
                <button
                  type="submit"
                  className="btn btn-primary btn-round mr-5"
                >
                  {sectionItem?._id ? `Update` : `Submit`}
                </button>
                <div
                  // to="/section/manage"
                  className="btn btn-primary btn-round"
                  data-bs-dismiss="offcanvas"
                  // type="reset"
                  onClick={(e) => {
                    e?.preventDefault();
                    setShow(false);
                  }}
                >
                  Cancel
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default TagsCreate;
