import React, { useState } from "react";
import ReactQuill from "react-quill";
import PropTypes from 'prop-types';

import "react-quill/dist/quill.snow.css";
import { countWordsAndChars } from "../../../common/count-words-and-characters";

const QuillEditor = (props) => {
  const { cards, subCard, handleSubCardChange, cardIndex, subCardIndex,onAutoSaveStory } =
    props;
  const [text, setText] = useState(
    cards[cardIndex].sub_card[subCardIndex].title || ""
  );

  const modules = {
    toolbar: [
      // [{ 'size': ['small', false, 'large', 'huge'] }], 
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      // [{ font: [] }],
      // [{ color: [] }, { background: [] }],
      [{ align: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"],
      // [{ header: '1' }, { header: '2' }, { font: [] }],
      // [{ size: [] }],
      // ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      // [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      // ['link', 'image', 'video'],
      // ['clean'],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "font",
    "color",
    "background",
    "align",
    "list",
    "bullet",
    "link",
    "image",
  ];

  return (
    <>
    <ReactQuill
      className="quill-editor " 
      theme="snow"
      value={cards[cardIndex].sub_card[subCardIndex].title}
      onChange={(e) => {
        setText(e);
        handleSubCardChange(cardIndex, subCardIndex, "title", e);
      }}
      onBlur={(e)=>{
        onAutoSaveStory()
      }}
      modules={modules}
      formats={formats}
      placeholder="Enter Title"
    />
    </>
  );
};

export default QuillEditor;
