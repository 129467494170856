import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useDropzone } from "react-dropzone";

const ContentForm = (props) => {
  const {
    setStepperForm,
    register,
    HandleUploadImage,
    imgUrl,
    setImgUrl,
    errors,
    inputs,
    HandleErrorChange,
    HandleNext,
    contentFormErr,
  } = props;
  const [files, setFiles] = useState([]);
  const thumbsContainer = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    flexWrap: "wrap",
    marginTop: 16,
  };

  const thumb = {
    display: "inline-flex",
    borderRadius: 2,
    border: "1px solid #eaeaea",
    marginBottom: 8,
    marginRight: 8,
    // width: 100,
    height: 100,
    padding: 4,
    boxSizing: "border-box",
  };

  const thumbInner = {
    display: "flex",
    minWidth: 0,
    overflow: "hidden",
  };

  const img = {
    display: "block",
    width: "auto",
    height: "100%",
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    onDrop: (acceptedFiles) => {
      setImgUrl("");
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      HandleUploadImage(acceptedFiles);
    },
  });

  const thumbs = imgUrl ? (
    <div style={thumb}>
      <div style={thumbInner}>
        <img src={imgUrl} style={img} />
      </div>
    </div>
  ) : (
    files?.map((file) => (
      <div style={thumb} key={file.name}>
        <div style={thumbInner}>
          <img
            src={file?.preview}
            style={img}
            onLoad={() => {
              URL.revokeObjectURL(file?.preview);
            }}
          />
        </div>
      </div>
    ))
  );

  useEffect(() => {
    return () => files.forEach((file) => URL.revokeObjectURL(file?.preview));
  }, []);
  return (
    <>
      <div className="card-body">
        <div className="row mb-15">
          <label htmlFor="title" className="col-sm-5 col-form-label text-right">
            Title
          </label>
          <div className="col-sm-3">
            <input
              type="text"
              className="form-control"
              name="title"
              placeholder="Title"
              {...register("title", { required: true })}
              onChange={HandleErrorChange}
            />
            {contentFormErr?.title && (
              <span className="text-danger">Title is required.</span>
            )}
          </div>
        </div>

        <div className="row mb-15">
          <label
            htmlFor="txtSummary"
            className="col-sm-5 col-form-label text-right"
          >
            Summary
          </label>
          <div className="col-sm-3">
            <input
              type="text"
              className="form-control"
              name="summary"
              placeholder="Summary"
              {...register("summary", { required: true })}
              onChange={HandleErrorChange}
            />
            {contentFormErr?.summary && (
              <span className="text-danger">Summary is required.</span>
            )}
          </div>
        </div>

        <div className="row mb-15">
          <label
            htmlFor="imgImage"
            className="col-sm-5 col-form-label text-right"
          >
            Image
          </label>
          <div className="col-sm-3">
            <div
              {...getRootProps({
                className: "dropzone border p-3 text-center",
              })}
            >
              <input {...getInputProps()} />
              <i className="fa fa-cloud-upload"></i>
              <p>Upload Image</p>
            </div>
            {/* {!imgUrl && <span className="text-danger">Image is required.</span>} */}
            <aside style={thumbsContainer}>{thumbs}</aside>
          </div>
        </div>

        {/* <div className="row mb-15">
          <label
            htmlFor="selTemplate"
            className="col-sm-5 col-form-label text-right"
          >
            Template
          </label>
          <div className="col-sm-3">
            <select
              name="template"
              {...register("template")}
              className="form-control"
            >
              <option value={""}>Please Select</option>
              <option value={"Website Development"}>Website Development</option>
              <option value={"Software Development"}>
                Software Development
              </option>
              <option value={"Mobile App Development"}>
                Mobile App Development
              </option>
              <option value={"Search Engine Optimisation"}>
                Search Engine Optimisation
              </option>
              <option value={"Social Media Management"}>
                Social Media Management
              </option>
            </select>
          </div>
        </div> */}
        <div className="col-sm-12 text-center">
          <div className="row">
            <div className="col-sm-5"></div>
            <div className="col-sm-3 justify-content-center">
              <button
                className="btn btn-primary btn-round mr-5"
                type="button"
                onClick={() => {
                  HandleNext();
                }}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContentForm;
