import { Link } from "react-router-dom";

const EbookSubscribers = () => {
  return (
    <>
      <div className="content">
        <div className="content">
          <div className="card col-md-12 m-auto p-30">
            <div className="card-header">
              <h3 className="text-bold">e-Book Subscribers</h3>
            </div>
            <div className="card-body">
              <div className="responsive">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>Industry</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>
                        Sachin Jaiswal
                      </td>
                      <td>
                        <a href="#">sachin@jithvar.com</a>
                      </td>
                      <td>
                        <a href="#">
                          +91-93996 32549
                        </a>
                      </td>
                      <td>Digital Marketing</td>
                      <th>
                        <a href="#">
                          <i className="fa fa-edit"></i>
                        </a>
                        <a href="#" className="ml-2">
                          <i className="fa fa-trash"></i>
                        </a>
                      </th>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>
                        Sachin Jaiswal
                      </td>
                      <td>
                        <a href="#">sachin@jithvar.com</a>
                      </td>
                      <td>
                        <a href="#">
                          +91-93996 32549
                        </a>
                      </td>
                      <td>Digital Marketing</td>
                      <th>
                        <a href="#">
                          <i className="fa fa-edit"></i>
                        </a>
                        <a href="#" className="ml-2">
                          <i className="fa fa-trash"></i>
                        </a>
                      </th>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>
                        Sachin Jaiswal
                      </td>
                      <td>
                        <a href="#">sachin@jithvar.com</a>
                      </td>
                      <td>
                        <a href="#">
                          +91-93996 32549
                        </a>
                      </td>
                      <td>Digital Marketing</td>
                      <th>
                        <a href="#">
                          <i className="fa fa-edit"></i>
                        </a>
                        <a href="#" className="ml-2">
                          <i className="fa fa-trash"></i>
                        </a>
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EbookSubscribers;