import React, { useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import useUserHook from "./hook";
import axios from "axios";
import config from "../../../config";
import { Link, redirect, useNavigate, useParams } from "react-router-dom";

const ForgetPasswordForm = () => {
  const { id } = useParams();
  const {
    register,
    handleSubmit,
    errors,
    setValue,
    onSubmit,
    navigate,
    handleForgetPassword,
    tempUrl,
    setTempUrl,
  } = useUserHook();
  const [email, setEmail] = useState("");

  console.log(id, "id");
  return (
    <>
      <div className="container h-p100 baackground_image">
        <div
          className=" row align-items-center justify-content-md-center height-100-vh h-p100 "
          style={{ minHeight: "400px !important" }}
        >
          <div className="col-lg-7 col-md-11 col-12">
            <div
              className="bg-white p-40 row align-items-center justify-content-md-center h-p100"
              data-overlay-light="5"
            >
              <div className="col-lg-6 col-md-6 col-12">
                <div className=" text-center content-bottom">
                  <img
                    src="../../../images/raftaarlogo.png"
                    className="no-border"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                <div className=" content-bottom p-lg-5 ">
                  <div className="content-top-agile p-lg-5 ">
                    <h2 className="text-bold">
                      {tempUrl == "" && `Forget Password`}
                      {tempUrl !== "" && `Link Generated`}
                    </h2>
                    <p></p>
                    <p></p>
                  </div>
                  <form
                    method="post"
                    onSubmit={handleSubmit(handleForgetPassword)}
                  >
                    {tempUrl !== "" && (
                      <div
                      className="py-5"
                        role="button"
                      >
                        <Link to={`${tempUrl?.toString()}`} >
                          <i className="fa fa-dashboard"></i>{" "}
                          <span>Click here to change your password.</span>
                        </Link>
                      </div>
                    )}
                    {tempUrl === "" && (
                      <>
                        <div className="form-group">
                          <div className="input-group mb-3 mt-4 ">
                            <input
                              type="text"
                              className={`form-control ${
                                errors.email ? "is-invalid" : ""
                              }`}
                              id="email"
                              placeholder="Email"
                              onChange={(e) => setEmail(e.target.value)}
                              {...register("email", {
                                required: "Please enter your email.",
                                pattern: {
                                  value:
                                    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                  message:
                                    "Please enter a valid email address.",
                                },
                              })}
                            />
                            {errors.email && (
                              <div className="invalid-feedback">
                                {errors.email.message}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12 justify-content-end">
                            <div
                              className="fog-pwd text-right"
                              onClick={(e) => {
                                navigate("/login");
                              }}
                            >
                              <a href="javascript:void(0)">
                                <i className="ion ion-locked"></i> Cancel
                              </a>
                              <br />
                            </div>
                          </div>

                          <div className="col-12 text-center mb-5">
                            <button
                              type="submit"
                              className="btn btn-danger btn-block margin-top-10"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgetPasswordForm;
