// Logout.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Clear session storage and redirect to login
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('user');
    navigate('/login');
  }, []);

  return <div>Logging out...</div>; // You can customize the content here
};

export default Logout;
