// constants.js
import { format } from "date-fns"; // Import date-fns format function


export const voting_setting = {
  Login: 'Login',
  Anonymous: 'Anonymous'
}


class Common {
  static status = {
    ACTIVE: 'Active',
    INACTIVE: "Inactive",
    IN_PROGRESS: 'In Progress',
    COMPLETED: 'Completed',
  };

  static getStatus(statusKey) {
    if (statusKey === Common.status.ACTIVE) {
      return <span className="badge badge-success">Active</span>;
    }
    else if (statusKey === Common.status.INACTIVE) {
      return <span className="badge badge-danger">Inactive</span>;
    }

    return Common.status[statusKey] || 'Unknown Status';
  }


  static truncateStringToWord(str, maxLength) {
    if (str.length <= maxLength) {
      return str;
    }

    const truncated = str.substr(0, maxLength);
    const lastSpaceIndex = truncated.lastIndexOf(' ');

    if (lastSpaceIndex !== -1) {
      return truncated.substr(0, lastSpaceIndex) + "...";
    }

    return truncated;
  }
  static formatDate = (dateString) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return new Date(dateString).toLocaleDateString('en-GB', options);
  };

  static formatDateTime = (dateString) => {
    const date = new Date(dateString);
    return format(date, "dd-MM-yyyy HH:mm"); // Format the date
  };

  static formatDatePM = (dateString) => {
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    const formattedDate = new Date(dateString).toLocaleDateString(undefined, options);
    const formattedTime = new Date(dateString).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true });
    return `${formattedDate} ${formattedTime}`;
  };

}

export default Common;
