import { Link } from "react-router-dom";

const SeoContent = () => {
  return (
    <>
      <div className="content">
        <div className="content">
          <div className="card col-md-5 m-auto p-30">
            <div className="card-header">
              <h3 className="text-bold">SEO Content</h3>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Page Name</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>
                        Digital Media Marketing
                      </td>
                      <th>
                        <Link to="/seo-content/update/928648264826438276">
                          <i className="fa fa-edit"></i>
                        </Link>
                      </th>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>
                        Paid Media Marketing
                      </td>
                      <th>
                        <a href="#">
                          <i className="fa fa-edit"></i>
                        </a>
                      </th>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>
                        Search Engine Optimisation
                      </td>
                      <th>
                        <a href="#">
                          <i className="fa fa-edit"></i>
                        </a>
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SeoContent;