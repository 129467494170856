import React from "react";
import { useForm } from "react-hook-form";
import config from "../../../config";
import { apiEndpoints } from "../../common/api/apiEndpoints";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { CreateDataApi } from "../../common/api/api";
import { useState } from "react";

const useUserHook = () => {
  const userData = sessionStorage.getItem("user");
  const user = userData ? JSON.parse(userData) : null;
  const navigate = useNavigate();
  const { id } = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const onSubmit = async (data) => {
    let payload = {
      email: user?.email,
      currentPassword: data?.currentPassword,
      newPassword: data?.newPassword,
      confirmPassword: data?.confirmPassword,
    };
    await axios
      .put(
        `${config.apiUrl}/${apiEndpoints?.changePassword(user?._id)}`,
        payload
      )
      .then((response) => {
        const { data: { message, isSuccess } = {} } = response;
        if (isSuccess) {
          toast.success(message);
          navigate("/dashboard");
        } else {
          toast.success(message);
        }
      })
      .catch((error) => {
        console.error("Error adding work:", error);
      });
  };
  const [tempUrl, setTempUrl] = useState("");
  const handleForgetPassword = async (data) => {
    const emailObject = {
      message_name: "Update Your Password",
      message_type: "Email",
      subject: "Forget Password",
      send_to: data?.email,
      updated_by: "",
    };
    const response = await CreateDataApi(`user/forget-password`, emailObject);
    setTempUrl(response?.tempUrl)
  };

  const handleChangePassword = async (data) => {

    let payload = {
      type: "forget",
      token:id,
      email: data?.email,
      newPassword: data?.newPassword,
      confirmPassword: data?.confirmPassword,
    };

    await axios
      .post(`${config.apiUrl}/${apiEndpoints?.forgetPassword}`, payload)
      .then((response) => {
        toast.success(response?.data?.message);
        navigate("/");
      })
      .catch((error) => {
        console.error("Error adding work:", error);
      });
  };

  return {
    register,
    handleSubmit,
    errors,
    setValue,
    onSubmit,
    handleForgetPassword,
    handleChangePassword,
    navigate,
    tempUrl, setTempUrl,
    id
  };
};

export default useUserHook;
