import React, { useContext, useEffect, useState } from "react";
import { ArrowLeftSquare } from "react-bootstrap-icons";
import { Badge, Row, Col, Card, Button } from "react-bootstrap";
import useCollectionHook from "../collectionHook";
import { Link } from "react-router-dom";
import CollectionCard from "./collection-card";
import CollectionModal from "./modal";
import { convertTimestamp } from "../../../common/utils/convertor/dateTime";
import useStoreHook from "../hooks/store.hook";
import StoredItem from "./storedItem";
import { useNavigate } from "react-router-dom";
import config from "../../../../config";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
const CollectionUpdate = () => {
  const [isTabActive, setIsTabActive] = useState("STORIES");
  const [hasMore, setHasMore] = useState(true);

  const handleTab = (tab) => {
    setIsTabActive(tab);
  };
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { getValues } = useCollectionHook();

  const {
    collectionOfCollectionList,
    onSubmit,
    id,
    user,
    onUpdate,
    loading,
    FetchCollectionOfCollectionList,
    storiesList,
    fetchOurStoryList,
    currentPage,
    totalPages,
    collectionList,
    fetchOurCollectionList,
    setCurrentPage,
    currentPageCollection,
    setCurrentPageCollection,

    onDragStart,
    onDragOver,
    onDrop,
    onDragLeave,

    handleTempCollectionUpdate,
  } = useStoreHook();

  const option = {
    name: "Disable backdrop",
    scroll: false,
    backdrop: false,
  };

  const Tab_Navigation = [
    { name: "Stories", listNumber: storiesList?.length ?? 0, value: "STORIES" },
    {
      name: "Collection",
      listNumber: collectionList?.collections?.length ?? 0,
      value: "COLLECTION",
    },
  ];

  const handleCollectionAdd = (data) => {
    const commonData = {
      title: data?.title ?? "",
      parent_collection_id: id ?? "",
      created_by: data?.created_by ?? "",
      updated_by: user.name ?? "",
      created_on: new Date() ?? "",
      updated_on: new Date() ?? "",
      page_view: "0",
      status: "temp",
    };
    const collectionData = {
      type: "default",
      collection_id: data?._id,
      ...commonData,
    };
    const storiesData = {
      type: data?.story_type,
      story_id: data?._id,
      ...commonData,
    };

    isTabActive === "STORIES"
      ? onSubmit(storiesData)
      : onSubmit(collectionData);
  };

  useEffect(() => {
    const deleteTempItemsOnLoad = async () => {
      try {
        const response = await axios.delete(
          `${config.apiUrl}/collection-of-collection/delete/temp?status=temp`
        );
        await FetchCollectionOfCollectionList();
      } catch (error) {
        console.error("Error deleting temp items:", error);
      }
    };

    deleteTempItemsOnLoad();
  }, []);

  const renderCollectionList = () => {
    return (
      collectionList?.collections?.length > 0 &&
      collectionList?.collections?.map((item, index) => (
        <div key={index} className="mb-5">
          <CollectionCard
            collectionOfCollectionList={
              collectionOfCollectionList?.length > 0
                ? collectionOfCollectionList
                : []
            }
            parentId={item?._id}
            title={item?.title}
            storyType="Default"
            timeStamp={moment(item?.created_on).format("DD MMM YYYY | hh:mm:ss a")}
            author={item?.created_by ?? "..."}
            onClickSubmit={() => handleCollectionAdd(item)}
          />
        </div>
      ))
    );
  };
  const renderStoriesList = () => {
    return (
      storiesList?.length &&
      storiesList?.map((item, index) => (
        <div key={index} className="mb-5">
          <CollectionCard
            collectionOfCollectionList={
              collectionOfCollectionList?.length > 0
                ? collectionOfCollectionList
                : []
            }
            title={item?.title ?? "..."}
            parentId={item?._id}
            storyType={item?.story_type ?? "..."}
            timeStamp={convertTimestamp(item?.timestamp) ?? "..."}
            author={item?.created_by ?? "..."}
            onClickSubmit={() => handleCollectionAdd(item)}
          />
        </div>
      ))
    );
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      FetchCollectionOfCollectionList();
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop ===
      document.documentElement.offsetHeight
    ) {
      if (currentPage < totalPages && !loading) {
        setCurrentPage((prevPage) => prevPage + 1);
        setCurrentPageCollection((prevPage) => prevPage + 1);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    fetchOurStoryList();
    fetchOurCollectionList();
    FetchCollectionOfCollectionList();
  }, []);

  return (
    <div className="py-4 px-3">
      <div className="col-md-12 m-auto ">
        <Card className="col-md-12 m-auto shadow-none border px-4 py-3 position-relative">
          <div>
            <Link
              to="/collection"
              className="btn btn-transparent btn-round btn-sm"
            >
              <div className="d-flex flex-row gap-2 align-items-center cursor-pointer border-0 rounded-1 bg-transparent">
                <ArrowLeftSquare />
                <h4 className="p-0 m-0">Collection</h4>
              </div>
            </Link>
          </div>
          <div className="d-flex flex-column gap-2">
            <h2 className="pt-2 fw-bold text-black">{getValues("title")}</h2>
            <span className="d-flex justify-content-between">
              <span>
                <Badge className="badge text-capitalize">
                  {getValues("story_template")}
                </Badge>
              </span>
              <div className="d-flex gap-3">
                <Button
                  className="btn-border bg-transparent shadow-none text-primary"
                  onClick={(e) => {
                    e?.preventDefault();
                    handleTempCollectionUpdate("publish");
                  }}
                >
                  Publish
                </Button>
                <Button
                  className="btn-border bg-transparent shadow-none text-primary"
                  onClick={handleShow}
                >
                  Setting
                </Button>
              </div>
            </span>
            <span>
              Last modified on {convertTimestamp(getValues("updated_on"))}
            </span>
          </div>
          <Row className="pt-4">
            <Col lg={6} md={12} xs={12}>
              <Card className="shadow-none border">
                <>
                  <div className="d-flex justify-content-between align-items-center px-20 py-10">
                    <h4 className="pt-2 fw-bolder text-black text-bold">
                      CONTENT LIST
                    </h4>
                    <i className="fa fa-refresh cursor-pointer" />
                  </div>

                  <div className="bg-secondary px-20 py-10">
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      onChange={(e) => {
                        fetchOurCollectionList("", e?.target?.value);
                        fetchOurStoryList("", e?.target?.value);
                      }}
                      data-table="your-table"
                      placeholder="Item to search.."
                    />
                    <div className="d-flex gap-4 align-items-center my-4 border-bottom border-black ">
                      {Tab_Navigation?.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className={`
                          ${
                            isTabActive === item?.value
                              ? " text-center active-stepper-form-head text-bolder mb-0 collection-border collection-text"
                              : "  text-bolder align-items-center cursor-pointer pb-2"
                          } mb-0 d-flex gap-2 `}
                            role="button"
                            onClick={() => handleTab(item?.value)}
                          >
                            <div>{item.name}</div>
                            <span
                              className={` ${
                                isTabActive === item.value ? "bg-primary" : ""
                              } px-3 rounded-1 `}
                            >
                              {item.listNumber ?? "0"}
                            </span>
                          </div>
                        );
                      })}
                    </div>
                    <div
                      className="pb-5"
                      style={{
                        minHeight:
                          (storiesList?.length > 0 ||
                            collectionList?.length > 0) &&
                          "500px",
                        overflowY:
                          (storiesList?.length > 0 ||
                            collectionList?.length > 0) &&
                          "scroll",
                        height:
                          (storiesList?.length > 0 ||
                            collectionList?.length > 0) &&
                          "1000px",
                      }}
                    >
                      <InfiniteScroll
                        id="scrollableDiv"
                        dataLength={
                          isTabActive === "COLLECTION"
                            ? collectionList?.length
                            : storiesList?.length
                        }
                        scrollableTarget="scrollableDiv"
                        // scrollThreshold={0.5}
                        inverse={false}
                        next={() => {
                          if (isTabActive === "COLLECTION") {
                            setCurrentPageCollection((prev) => {
                              return prev + 1;
                            });
                            fetchOurCollectionList(
                              "",
                              "",
                              currentPageCollection
                            );
                          } else {
                            setCurrentPage((prev) => {
                              return prev + 1;
                            });
                            fetchOurStoryList("", "", currentPage);
                          }
                        }}
                        // onScroll={(e)=>{
                        //   setCurrentPage(currentPage + 1)
                        //   fetchOurStoryList("", "", currentPage + 1);
                        // }}
                        hasMore={hasMore}
                        loader={<h4 className="pb-5">Loading...</h4>}
                      >
                        
                        {isTabActive === "COLLECTION" && (
                          <div className="pb-25">
                            {collectionList?.collections?.length > 0 ? (
                              <>{renderCollectionList()} </>
                            ) : (
                              <div>
                                <h4>Collection not found</h4>
                              </div>
                            )}
                          </div>
                        )}
                        {isTabActive === "STORIES" && (
                          <div className="pb-4">
                            {storiesList?.length > 0 ? (
                              renderStoriesList()
                            ) : (
                              <div>
                                <h4>Stories not found</h4>
                              </div>
                            )}
                          </div>
                        )}
                      </InfiniteScroll>
                    </div>
                  </div>
                </>
              </Card>
            </Col>
            <Col lg={6} md={12} xs={12}>
              <Card className="shadow-none border">
                <>
                  <div className="d-flex justify-content-between align-items-center px-20 py-10">
                    <h4 className="pt-2 fw-bolder text-black text-bold">
                      SORTED ITEMS
                    </h4>
                    <h5 className="fw-bold m-0 p-0">
                      {collectionOfCollectionList?.length && 0}
                      /50
                    </h5>
                  </div>

                  <div className="px-20 py-10 bg-secondary pb-60">
                    <div
                      className="px-20 py-10 bg-secondary pb-60"
                      style={{
                        minHeight:
                          collectionOfCollectionList?.length > 0 && "500px",
                        overflowY:
                          collectionOfCollectionList?.length > 0 && "scroll",
                        maxHeight:
                          collectionOfCollectionList?.length > 0 && "1000px",
                      }}
                    >
                      {collectionOfCollectionList?.length > 0 ? (
                        collectionOfCollectionList?.map((item, index) => {
                          return (
                            <div
                              className={`drag-cursor mb-3`}
                              key={index}
                              data-position={index}
                              draggable
                              onDragStart={onDragStart}
                              onDragOver={onDragOver}
                              onDrop={onDrop}
                              onDragLeave={onDragLeave}
                            >
                              <StoredItem data={item} />
                            </div>
                          );
                        })
                      ) : (
                        <h4>Stored Item not found</h4>
                      )}
                    </div>
                  </div>
                </>
              </Card>
            </Col>
          </Row>
        </Card>
      </div>
      <CollectionModal handleClose={handleClose} show={show} {...option} />
    </div>
  );
};

export default CollectionUpdate;
