import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import usePermissionGroupHook from "./permissionGroupHook";
import $ from "jquery";
import { Badge } from "react-bootstrap";
import { CheckAccess } from "../../../common/utils/roleAccess";

const ListPermissionGroup = () => {
  const {
    id,
    navigate,
    fetchPermissionGroupList,
    listPermissionGroup,
    register,
    handleSubmit,
    setValue,
    handleDelete,
  } = usePermissionGroupHook();
  const [loading, setIsLoading] = useState(true);
  let loader = $("#loader");
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, []);
  return (
    <>
      <div className="content">
        <div className="card col-md-12 m-auto p-30">
          <div className="card-header">
            <h3 className="text-bold">Permission Groups</h3>
            {CheckAccess("permission group", "index") && (
              <div className="timeline-footer text-right">
                <Link
                  to="/user-management/permissions-group/create"
                  className="btn btn-primary btn-round btn-sm"
                >
                  Add Permission Group
                </Link>
              </div>
            )}
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ height: "50px" }}>
                    <th scope="col"></th>
                    <th scope="col">
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => {
                          fetchPermissionGroupList(e.target.value, "");
                        }}
                        placeholder="Item to search.."
                      />
                    </th>
                    <th scope="col">
                      <select
                        name="status"
                        className="form-control"
                        onChange={(e) => {
                          fetchPermissionGroupList("", e.target.value);
                        }}
                      >
                        <option value={""}>All</option>
                        <option value={"Active"}>Active</option>
                        <option value={"Inactive"}>Inactive</option>
                      </select>
                    </th>
                    {/* <th scope="col"> 
                        <input type="text" className="form-control"
                          // onChange={(e)=>{fetchPages("","",e.target.value,"")}}
                        data-table="your-table" placeholder="Item to search.." />
                                          </th> */}
                  </tr>
                  {!listPermissionGroup?.length > 0 && loading ? (
                    <tr>
                      <div id="loader">
                        <div class="d-flex flex-column align-items-center">
                          <div class="spinner-grow text-gold"></div>
                          <span class="text-gold mt-1">Loading...</span>
                        </div>
                      </div>
                    </tr>
                  ) : (
                    listPermissionGroup?.map((group, index) => {
                      return (
                        <tr key={group._id}>
                          <td>{index + 1}</td>
                          <td>{group.name}</td>
                          {/* <td>{group.status}</td> */}
                          <td>
                            <Badge
                              bg={
                                group.status === "Active" ? "success" : "danger"
                              }
                              text="white"
                            >
                              {group.status}
                            </Badge>
                          </td>
                          <td>
                            {/* <Link to={`/permission-groups/${group.slug}`} className="mr-2"><i className="fa fa-eye"></i></Link> */}
                            {CheckAccess("permission group", "update") && (
                              <Link
                                to={`/user-management/permissions-group/update/${group._id}`}
                                className="mr-2"
                              >
                                <i className="fa fa-edit"></i>
                              </Link>
                            )}
                            {CheckAccess("permission group", "delete") && (
                              <Link onClick={() => handleDelete(group._id)}>
                                <i className="fa fa-trash"></i>
                              </Link>
                            )}
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListPermissionGroup;
