import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css"; // Import styles
import ContentForm from "./stepperForm/contentForm";
import MetaDateForm from "./stepperForm/metaDateForm";
import RulesForm from "./stepperForm/rulesForm";
import useCollectionHook from "./collectionHook";
import { Link } from "react-router-dom";

const CreateCollections = () => {
  const {
    navigate,
    register,
    handleSubmit,
    handleChange,
    rulesType,
    setRulesType,
    user,
    onSubmit,
    HandleSelectChange,
    tagsList,
    setTagsList,
    selectedTagsList,
    setSelectedTagsList,
    storyTemplate,
    setStoryTemplate,
    selectedStoryTemplate,
    setSelectedStoryTemplate,
    errors,
    includeSectionList,
    setIncludeSectionList,
    selectedIncludeSection,
    setSelectedIncludeSection,
    excludeSectionList,
    setExcludeSectionList,
    selectedExcludeSection,
    setSelectedExcludeSection,
    id,
    onUpdate,
    authorList,
    selectedAuthorList,
    sortedByList,
    selectedSortedBy,
    stepperForm,
    setStepperForm,
    HandleUploadImage,
    imgUrl,
    setImgUrl,
    isAnyFieldFilled,
    setIsAnyFieldFilled,
    inputs,
    setInputs,
    HandleErrorChange,
    HandleNext,
    contentFormErr,
  } = useCollectionHook();

  return (
    <>
      <div className="content">
        <div className="card col-md-12 m-auto p-30">
          <div className="card-header text-center">
            <div className={`col-md-6`}>
              <h3
                className={
                  stepperForm === "contentForm"
                    ? `text-bold stepper-form-head active-stepper-form-head`
                    : `text-bold stepper-form-head`
                }
                onClick={() => {
                  setStepperForm("contentForm");
                }}
              >
                Content
              </h3>
            </div>
            {/* <div className={`col-md-4`}>
                <h3 className={stepperForm === 'MetaDateForm' ? `text-bold stepper-form-head active-stepper-form-head` : `text-bold stepper-form-head`} onClick={()=>{setStepperForm('MetaDateForm')}}>
                  Meta Data
                </h3>
              </div> */}
            <div className={`col-md-6`}>
              <h3
                className={
                  stepperForm === "RulesForm"
                    ? `text-bold stepper-form-head active-stepper-form-head`
                    : `text-bold stepper-form-head`
                }
                // onClick={() => {
                //   setStepperForm("RulesForm");
                // }}
              >
                Rules
              </h3>
            </div>
          </div>
          <div className="card-body">
            <form
              onSubmit={id ? handleSubmit(onUpdate) : handleSubmit(onSubmit)}
            >
              {stepperForm === "contentForm" && (
                <ContentForm
                  setStepperForm={setStepperForm}
                  navigate={navigate}
                  register={register}
                  handleSubmit={handleSubmit}
                  handleChange={handleChange}
                  rulesType={rulesType}
                  setRulesType={setRulesType}
                  user={user}
                  HandleUploadImage={HandleUploadImage}
                  imgUrl={imgUrl}
                  setImgUrl={setImgUrl}
                  errors={errors}
                  inputs={inputs}
                  setInputs={setInputs}
                  HandleErrorChange={HandleErrorChange}
                  HandleNext={HandleNext}
                  contentFormErr={contentFormErr}
                />
              )}
              {/* {stepperForm === 'MetaDateForm' && <MetaDateForm />} */}
              {stepperForm === "RulesForm" && (
                <RulesForm
                  setStepperForm={setStepperForm}
                  navigate={navigate}
                  errors={errors}
                  register={register}
                  handleSubmit={handleSubmit}
                  handleChange={handleChange}
                  rulesType={rulesType}
                  setRulesType={setRulesType}
                  user={user}
                  onSubmit={onSubmit}
                  HandleSelectChange={HandleSelectChange}
                  tagsList={tagsList}
                  setTagsList={setTagsList}
                  selectedTagsList={selectedTagsList}
                  setSelectedTagsList={setSelectedTagsList}
                  storyTemplate={storyTemplate}
                  setStoryTemplate={setStoryTemplate}
                  selectedStoryTemplate={selectedStoryTemplate}
                  setSelectedStoryTemplate={setSelectedStoryTemplate}
                  includeSectionList={includeSectionList}
                  setIncludeSectionList={setIncludeSectionList}
                  selectedIncludeSection={selectedIncludeSection}
                  setSelectedIncludeSection={setSelectedIncludeSection}
                  excludeSectionList={excludeSectionList}
                  setExcludeSectionList={setExcludeSectionList}
                  selectedExcludeSection={selectedExcludeSection}
                  setSelectedExcludeSection={setSelectedExcludeSection}
                  id={id}
                  authorList={authorList}
                  selectedAuthorList={selectedAuthorList}
                  sortedByList={sortedByList}
                  selectedSortedBy={selectedSortedBy}
                  isAnyFieldFilled={isAnyFieldFilled}
                  setIsAnyFieldFilled={setIsAnyFieldFilled}
                />
              )}
              {stepperForm === "RulesForm" ? (
                <div className="row mb-15">
                  <div className="col-sm-12 text-center">
                    <button
                      className="btn btn-primary btn-round mr-5"
                      type="submit"
                    >
                      {id ? `Update` : `Submit`}
                    </button>
                    <Link
                      to="/collection"
                      className="btn btn-primary btn-round"
                      type="reset"
                    >
                      Cancel
                    </Link>
                  </div>
                </div>
              ) : (
                ""
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateCollections;
