import React, { useState, useEffect } from "react";
import axios from "axios";
import config from '../../../config';
import { Link, useParams } from "react-router-dom";
import Common from "../../common/Common";


const ViewContactRequest = () => {
  const [contactRequest, setContactRequest] = useState([]);

  const { id } = useParams();
  useEffect(() => {
    // Fetch contact requests from the API
    axios.get(`${config.apiUrl}/contacts/${id}`)
      .then(response => {
        setContactRequest(response.data);
      })
      .catch(error => {
        console.error('Error fetching contact requests:', error);
      });
  }, []); // Empty dependency array ensures this effect runs only once
  return (
    <>
      <div className="content">
        <div className="content">
          <div className="card col-md-12 m-auto p-30">
            <div className="card-header">
              <h3 className="text-bold">Contact Request</h3>
            </div>
            <div className="card-body">
              <div className="responsive">
                <table className="table table-hover">
                  <tbody>
                    <tr>
                      <th style={{ width: "20%" }}>Name</th>
                      <td>{contactRequest.name}</td>
                    </tr>
                    <tr>
                      <th>Email</th>
                      <td>{contactRequest.email}</td>
                    </tr>
                    <tr>
                      <th>Phone</th>
                      <td>{contactRequest.phone}</td>
                    </tr>
                    <tr>
                      <th>Service</th>
                      <td>{contactRequest.service}</td>
                    </tr>
                    <tr>
                      <th>Notes</th>
                      <td>{contactRequest.notes}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  );
}

export default ViewContactRequest;