import useUserHook from "./hook";

const ChangePassword = () => {
  const { register, handleSubmit, errors, setValue ,onSubmit} = useUserHook();
  return (
    <>
      <div className="content">
        <div className="content">
          <div className="card col-md-5 m-auto p-30">
            <div className="card-header">
              <h3 className="text-bold">Change Password</h3>
              {/* <div class="timeline-footer text-right">
              <Link to="/masters/payment-terms/create" class="btn btn-primary btn-round btn-sm">Add New</Link>
            </div> */}
            </div>
            <div className="card-body">
              <form
                // method="post"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="currentPassword">Current Password</label>
                      <input
                        className="form-control"
                        placeholder="Current Password"
                        name="currentPassword"
                        type="password"
                        {...register("currentPassword", { required: true })}
                      />
                      {errors.currentPassword && (
                        <span className="text-danger">
                          Current password is required.
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="newPassword">New Password</label>
                      <input
                        className="form-control"
                        placeholder="New Password"
                        name="newPassword"
                        type="password"
                        {...register("newPassword", { required: true })}
                      />
                      {errors?.newPassword && (
                        <span className="text-danger">
                          Current password is required.
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="confirmPassword">Confirm Password</label>
                      <input
                        className="form-control"
                        placeholder="Confirm Password"
                        name="confirmPassword"
                        type="password"
                        {...register("confirmPassword", { required: true })}
                      />
                      {errors?.confirmPassword && (
                        <span className="text-danger">
                          Current password is required.
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group text-center">
                      <button
                        type="submit"
                        className="btn btn-primary btn-round"
                      >
                        Change Password
                      </button>
                      {/* <button className="btn btn-danger btn-round" type='reset'>Reset</button> */}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
