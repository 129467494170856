import { Link } from "react-router-dom";

const PostJob = () => {
  return (
    <>
      <div className="content">
        <div className="content">
          <div className="card col-md-12 m-auto p-30">
            <div className="card-header">
              <h3 className="text-bold">Post Job</h3>
            </div>
            <div className="card-body">
              <form method="post">

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="jobTitle">Job Title</label>
                      <input type="text" placeholder="Job Title" className="form-control" name="jobTitle" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="designation">Designation</label>
                      <input type="text" placeholder="Designation" className="form-control" name="designation" />
                    </div>
                  </div>
                </div>


                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="minExperience">Min Experience</label>
                      <input type="text" placeholder="Min Experience" className="form-control" name="minExperience" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="maxExperience">Max Experience</label>
                      <input type="text" placeholder="Max Experience" className="form-control" name="maxExperience" />
                    </div>
                  </div>
                </div>


                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="minSalary">Min Salary</label>
                      <input type="text" placeholder="Min Salary" className="form-control" name="minSalary" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="maxSalary">Max Salary</label>
                      <input type="text" placeholder="Max Salary" className="form-control" name="maxSalary" />
                    </div>
                  </div>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PostJob;