import { useContext, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { Offcanvas } from "react-bootstrap";
import {
  CreateDataApi,
  GetListApi,
  UpdateDataApi,
} from "../../../common/api/api";
import { apiEndpoints } from "../../../common/api/apiEndpoints";
import moment from "moment";
import AppContext from "../../../appContext";

const EditorNotes = (props) => {
  const {
    storyId,
    setStoryStatus,
    showEditorNotes,
    setShowEditorNotes,
    fetchTimeline
  } = props;
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm();
  const { appData } = useContext(AppContext);

  const userData = localStorage.getItem("user");
  const user = userData ? JSON.parse(userData) : null;
  const [notes, setNotes] = useState([]);
  const handleCloseCanvas = () => setShowEditorNotes(false);
  const handleCreateEditorNotes = async () => {
    let payload = {
      story_id: storyId,
      created_by: user?._id,
      created_on: moment()?.format("MMMM DD, YYYY | hh:mm:s a"),
      editor_note: getValues()?.editor_note,
    };
    const response = await CreateDataApi(
      `${apiEndpoints.editorNotes}`,
      payload
    );

    if (response) {
      // setStoryStatus(response?.status);  
      setValue("editor_note", "");
      fetchNote(storyId);
    }
  };
  const fetchNote = async (id) => {
    const response = await GetListApi(`${apiEndpoints.getEditorNotes}`, {
      storyId: appData?.story_Id,
    });

    if (response) {
      setNotes(response?.EditorNotes);
    }
  };

  useEffect(() => {
    fetchNote(storyId);
  }, [storyId]);
  return (
    <div>
      <Offcanvas
        // className="story-canvas"
        show={showEditorNotes}
        onHide={handleCloseCanvas}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="mt-4 text-uppercase">
            Editor Notes
          </Offcanvas.Title>
        </Offcanvas.Header>
        <form>
          <div className="publish">
            <h5 className="fw-bold">Note</h5>

            <div className="col-sm-12 px-0">
              <div className="input-group date">
                <input
                  type="input"
                  className="form-control pull-right"
                  id={`note`}
                  name="editor_note"
                  {...register("editor_note")}
                />
              </div>
              <button
                type="submit"
                className={`btn btn-primary mt-4`}
                onClick={(e) => {
                  e?.preventDefault();
                  handleCreateEditorNotes();
                }}
              >
                Save
              </button>
            </div>
          </div>
        </form>
        <div className="publish overflow-scroll">
          <table className="table table-hover">
            <thead>
              <tr>
                <th>#</th>
                <th>Note</th>
                <th>Created by</th>
                <th>Created on</th>
              </tr>
            </thead>
            <tbody>
              {notes?.length > 0 &&
                notes?.map((note, key) => {
                  return (
                    <tr key={key}>
                      <td>{key + 1}.</td>
                      <td>{note?.editor_note || `-`}</td>
                      <td>{note?.created_by?.name || `-`}</td>
                      <td>{note?.created_on || `-`}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </Offcanvas>
    </div>
  );
};

export default EditorNotes;
