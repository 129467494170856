import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";
const animatedComponents = makeAnimated();

const RulesForm = (props) => {
  const {
    errors,
    register,
    handleChange,
    rulesType,
    HandleSelectChange,
    tagsList,
    selectedTagsList,
    storyTemplate,
    selectedStoryTemplate,
    includeSectionList,
    selectedIncludeSection,
    excludeSectionList,
    selectedExcludeSection,
    id,
    authorList,
    selectedAuthorList,
    sortedByList,
    selectedSortedBy,
    isAnyFieldFilled,
    setIsAnyFieldFilled,
  } = props;

  return (
    <>
      <div className="card-body">
        <div className="row mb-15 justify-content-center ">
          <div className="col-sm-8">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <label htmlFor="selTags" className="col-form-label pr-2">
                  Manual
                </label>
                <div>
                  <input
                    type="radio"
                    name="automated"
                    value="manual"
                    checked={rulesType === "manual" ? true : false}
                    {...register("automated")}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>
              <div className="d-flex align-items-center">
                <label htmlFor="selTags" className="col-form-label pr-2">
                  Automated
                </label>
                <div>
                  <input
                    type="radio"
                    name="automated"
                    value="automated"
                    checked={rulesType === "automated" ? true : false}
                    {...register("automated")}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {rulesType === "automated" ? (
          <>
            <div className="mb-15">
              <label htmlFor="tags" className=" col-form-label text-right">
                Tags
              </label>
              <div className="">
                <Select
                  className={`outline-none`}
                  name="tags"
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  options={tagsList}
                  isMulti
                  {...register("tags", { required: isAnyFieldFilled })}
                  onChange={(e) => HandleSelectChange("tags", e)}
                  value={selectedTagsList}
                ></Select>

                {errors.tags && (
                  <span className="text-danger">Tags is required.</span>
                )}
              </div>
            </div>

            <div className="mb-15">
              <label
                htmlFor="selIncludeSections"
                className=" col-form-label text-right"
              >
                Include Sections
              </label>
              <div className="">
                <Select
                  className={`outline-none`}
                  name="include_section"
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  isMulti
                  options={includeSectionList}
                  value={selectedIncludeSection}
                  {...register("include_section", {
                    required: isAnyFieldFilled,
                  })}
                  onChange={(e) => HandleSelectChange("include_section", e)}
                ></Select>
                {errors.include_section && (
                  <span className="text-danger">
                    Include section is required.
                  </span>
                )}
              </div>
            </div>

            <div className=" mb-15">
              <label
                htmlFor="selExcludeSections"
                className=" col-form-label text-right"
              >
                Exclude Sections
              </label>
              <div className="">
                <Select
                  className={`outline-none`}
                  name="exclude_section"
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  isMulti
                  options={excludeSectionList}
                  {...register("exclude_section", {
                    required: isAnyFieldFilled,
                  })}
                  onChange={(e) => HandleSelectChange("exclude_section", e)}
                  value={selectedExcludeSection}
                ></Select>
                {errors.exclude_section && (
                  <span className="text-danger">
                    Exclude section is required.
                  </span>
                )}
              </div>
            </div>

            <div className=" mb-15">
              <label
                htmlFor="storyTemplates"
                className=" col-form-label text-right"
              >
                Story Templates
              </label>
              <div className="">
                <Select
                  className={`outline-none`}
                  name="story_template"
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  options={storyTemplate}
                  {...register("story_template", {
                    required: isAnyFieldFilled,
                  })}
                  onChange={(e) => HandleSelectChange("story_template", e)}
                  value={selectedStoryTemplate}
                ></Select>

                {errors.story_template && (
                  <span className="text-danger">
                    Story template is required.
                  </span>
                )}
              </div>
            </div>

            <div className="mb-15">
              <label
                htmlFor="selAuthors"
                className=" col-form-label text-right"
              >
                Authors
              </label>
              <div className="">
                <Select
                  className={`outline-none`}
                  name="author"
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  options={authorList}
                  {...register("author", { required: isAnyFieldFilled })}
                  onChange={(e) => HandleSelectChange("author", e)}
                  value={selectedAuthorList}
                ></Select>
                {errors.author && (
                  <span className="text-danger">Author is required.</span>
                )}
              </div>
            </div>

            <div className=" mb-15">
              <label
                htmlFor="selSortedBy"
                className=" col-form-label text-right"
              >
                Sorted By
              </label>
              <div className="">
                <Select
                  className={`outline-none`}
                  name="sort_by"
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  options={sortedByList}
                  {...register("sort_by")}
                  onChange={(e) => HandleSelectChange("sort_by", e)}
                  value={selectedSortedBy}
                ></Select>
                {errors.sort_by && (
                  <span className="text-danger">Sort by is required.</span>
                )}
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default RulesForm;
