import React, { useEffect, useState } from "react";
import { TwitterTweetEmbed } from "react-twitter-embed";

const LinkPreview = ({ link }) => {
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState("");
  const [urlType, setUrlType] = useState("");

  const getUrlType = (itemUrl) => {
    try {
      const iframeRegex = /<iframe.*<\/iframe>/i;
      if (iframeRegex.test(itemUrl)) {
        setUrlType("iframe");
        setUrl(itemUrl);
        setLoading(false);
        return;
      }
      const instaBlockq = /<blockquote.*<\/blockquote>/i;
      if (instaBlockq.test(itemUrl)) {
        setUrlType("iframe");
        setUrl(itemUrl);
        setLoading(false);
        return;
      }
      const parsedUrl = new URL(itemUrl);
      const host = parsedUrl.hostname.toLowerCase();
      if (host.includes("youtube")) {
        let yUrl = convertToEmbedUrl(itemUrl);
        if (yUrl) {
          setUrl(yUrl);
        }
        setUrlType("YouTube");
        setLoading(false);
        return "YouTube";
      } else if (host.includes("twitter")) {
        setUrlType("Twitter");
        let yUrl = getTweetIdFromUrl(itemUrl);
        if (yUrl) {
          setUrl(yUrl);
        }
        setLoading(false);
        return "Twitter";
      } else {
        return "Unknown";
      }
    } catch (error) {
      return "Invalid URL";
    }
  };
  //for youtube
  const convertToEmbedUrl = (url) => {
    try {
      const parsedUrl = new URL(url);
      const host = parsedUrl.hostname.toLowerCase();

      if (host.includes("youtube")) {
        const videoId = parsedUrl.searchParams.get("v");
        if (videoId) {
          setLoading(false);
          return `https://www.youtube.com/embed/${videoId}`;
        }
      }

      return "Not a YouTube URL";
    } catch (error) {
      return "Invalid URL";
    }
  };
  //for tweeter
  const getTweetIdFromUrl = (url) => {
    const segments = url.split("/");
    return segments[segments.length - 1];
  };

  useEffect(() => {
    setLoading(true);
    const intervalId = setInterval(() => {
      getUrlType(link);
    }, 3000);
    return () => clearInterval(intervalId);
  }, [link]);

  return (
    <div className={`mt-2 position-relative`}>
      {loading ? (
        <div className={!url ? "h-100" : ""}>
          <div className="spinner"></div>
        </div>
      ) : (
        ""
      )}
      {url && urlType === "YouTube" && (
        <iframe width="100%" height="315" src={url} allowFullScreen></iframe>
      )}

      {url && urlType === "Twitter" && <TwitterTweetEmbed tweetId={url} />}

      {url && urlType === "iframe" && (
        <div>
          <div dangerouslySetInnerHTML={{ __html: url }} />
        </div>
      )}
    </div>
  );
};

export default LinkPreview;
