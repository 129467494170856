import React, { useState } from "react";
import { useForm } from "react-hook-form";
import usePermissionGroupHook from "./permissionGroupHook";
import axios from "axios";
import config from "../../../../config";
import { useEffect } from "react";
import { apiEndpoints } from "../../../common/api/apiEndpoints";

const PermissionGroupForm = () => {
  const {
    id,
    navigate,
    onSubmitPermissionGroup,
    onUpdatePermissionGroup,
    fetchPermissionGroupList,
    listPermissionGroup,
    register,
    handleSubmit,
    errors,
    setValue,
    handleDelete,
  } = usePermissionGroupHook();
  const [formData, setFormData] = useState({
    groupName: "",
    status: "active",
  });
  const fetchPermissionGroupListById = async (id) => {
    try {
      const response = await axios.get(`${config.apiUrl}/${apiEndpoints?.permissionGroupById(id)}`);
      if (response?.data) {
        setValue("name", response.data.name);
        setValue("status", response.data.status);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  
  useEffect(()=>{
    if(id){
      fetchPermissionGroupListById(id)
    }
  },[id])
  return (
    <>
      <div className="content">
        <div className="content">
          <div className="card col-md-12 m-auto p-30">
            <div className="card-header 0">
              <h3 className="text-bold">{}</h3>
            </div>
            <div className="card-body">
              <form
                onSubmit={
                  id
                    ? handleSubmit(onUpdatePermissionGroup)
                    : handleSubmit(onSubmitPermissionGroup)
                }
              >
                <div>
                  <div className="row mb-15">
                    <label
                      htmlFor="name"
                      className="col-sm-4 col-form-label text-right"
                    >
                      Group Name
                    </label>
                    <div className="col-sm-3">
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        placeholder="Group Name"
                        {...register("name", { required: true })}
                      />
                      {errors.name && (
                        <span className="text-danger">
                          Group Name is required.
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row mb-15">
                  <label
                    htmlFor="status"
                    className="col-sm-4 col-form-label text-right"
                  >
                    Status
                  </label>
                  <div className="col-sm-3">
                    <select
                      name="status"
                      {...register("status", { required: true })}
                      className="form-control"
                      defaultValue={'Active'}
                    >
                      <option value={"Active"}>Active</option>
                      <option value={"Inactive"}>Inactive</option>
                    </select>
                  </div>
                </div>
                <div className="row mb-15">
                  <div className="col-sm-12 text-center">
                    <button
                      className="btn btn-primary btn-round mr-5"
                      type="submit"
                    >
                      {id?`Update Permission Group`:`Create Permission Group`}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PermissionGroupForm;
