// ListPages.js
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import config from "../../../config";
import { CheckAccess } from "../../common/utils/roleAccess";
import { Pencil, Trash } from "react-bootstrap-icons";
import usePageHook from "./pageHook";

const ListPages = () => {
  const [loading, setLoading] = useState(true);
  const { handleDelete, pagesList, FetchPageList } = usePageHook();

  return (
    <>
      <div className="content">
        <div className="content">
          <div className="card col-md-12 m-auto p-30">
            <div className="card-header">
              <h3 className="text-bold">Pages</h3>
              {CheckAccess("pages", "create") && (
                <div className="timeline-footer text-right">
                  <Link
                    to="/pages/create"
                    className="btn btn-primary btn-round btn-sm"
                  >
                    Add Page
                  </Link>
                </div>
              )}
            </div>
            <div className="card-body">
              <div className="">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Title</th>
                      {/* Add more table headers as needed */}
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr style={{ height: "50px" }} className="bg-light">
                      <th scope="col"></th>
                      <th scope="col">
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          onChange={(e) => {
                            FetchPageList("", e.target.value, "", "");
                          }}
                          data-table="your-table"
                          placeholder="Item to search.."
                        />
                      </th>
                      <th scope="col">
                        <select name="status" className="form-control">
                          <option value={"All"}>All</option>
                          <option value={"Active"}>Active</option>
                          <option value={"Inactive"}>Inactive</option>
                        </select>
                      </th>
                      <th scope="col"></th>
                    </tr>
                    {console.log(pagesList, "pageList")}
                    {pagesList?.length > 0 ? (
                      pagesList.map((page, index) => (
                        <tr key={page._id}>
                          <td>{index + 1}</td>
                          <td>{page.title}</td>
                          <td>{page.status}</td>
                          <td>
                            {CheckAccess("pages", "update") && (
                              <Link
                                to={`/pages/update/${page._id}`}
                                className="mr-2"
                              >
                                <button type="button" className="btn btn">
                                  <Pencil
                                    color="royalblue"
                                    size={16}
                                    data-toggle="tooltip"
                                    title="Edit"
                                  />
                                </button>
                              </Link>
                            )}
                            {/* Add delete functionality */}
                            {CheckAccess("pages", "delete") && (
                              <button
                                type="button"
                                className="btn btn"
                                onClick={() => handleDelete(page?._id)}
                              >
                                <Trash
                                  color="royalblue"
                                  size={16}
                                  data-toggle="tooltip"
                                  title="Delete"
                                />
                              </button>
                            )}
                          </td>
                        </tr>
                      ))
                    ) : loading ? (
                      <tr>
                        <td colSpan="4">Loading...</td>
                      </tr>
                    ) : (
                      <tr>
                        <td colSpan="4">Pages not found...</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListPages;
