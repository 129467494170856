import React, { useEffect, useState } from "react";
import { TwitterTweetEmbed } from "react-twitter-embed";

const LinkPreview = ({
  link,
  handleSubCardChange,
  cardIndex,
  subCardIndex,
}) => {
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState("");
  const [urlType, setUrlType] = useState("");

  const getUrlType = (itemUrl) => {
    try {
      const iframeRegex = /<iframe.*<\/iframe>/i;
      if (iframeRegex.test(itemUrl)) {
        setUrlType("iframe");
        setUrl(itemUrl);
        setLoading(false);
        return;
      }
      const instaBlockq = /<blockquote.*<\/blockquote>/i;
      if (instaBlockq.test(itemUrl)) {
        setUrlType("iframe");
        setUrl(itemUrl);
        setLoading(false);
        return;
      }
      const parsedUrl = new URL(itemUrl);
      const host = parsedUrl?.hostname?.toLowerCase();
      if (host.includes("youtube")) {

        let yUrl = convertToEmbedUrl(itemUrl);
        if (yUrl) {
          setUrl(yUrl);
        }
        setUrlType("YouTube");
        setLoading(false);
        return "YouTube";

      } else if (host.includes("instagram")) {

        setLoading(false);

        setUrlType("instagram");
        const postID = link.split("/")[4];
        const embedUrl = `https://www.instagram.com/p/${postID}/embed/`;
        
        setUrl(embedUrl);
        if (embedUrl) {
        }
        setLoading(false);
        return "instagram";

      } else if (host.includes("twitter")) {

        setUrlType("Twitter");
        let yUrl = getTweetIdFromUrl(itemUrl);
        if (yUrl) {
          setUrl(yUrl);
        }
        setLoading(false);
        return "Twitter";

      } else {
        return "Unknown";
      }
    } catch (error) {
      return "Invalid URL";
    }
  };
  // for youtube
  const convertToEmbedUrl = (url) => {
    try {
      const parsedUrl = new URL(url);
      const host = parsedUrl?.hostname?.toLowerCase();

      if (host.includes("youtube")) {
        const videoId = parsedUrl.searchParams.get("v") || parsedUrl.pathname?.split("/")[2];
        if (videoId) {
          setLoading(false);
          return `https://www.youtube.com/embed/${videoId}`;
        }
      }

      return "Not a YouTube URL";
    } catch (error) {
      return "Invalid URL";
    }
  };

  // for twitter
  const getTweetIdFromUrl = (url) => {
    const segments = url.split("/");
    return segments[segments.length - 1];
  };

  useEffect(() => {
    setLoading(true);
    const intervalId = setInterval(() => {

      getUrlType(link);

    }, 3000);
    return () => clearInterval(intervalId);
  }, [link]);

  // https://www.facebook.com/groups/6623200774/permalink/10161556396100775/
  return (
    <div className={`mt-2 position-relative text-center`}>
      {loading ? (
        <div className={!url ? "h-100" : ""}>
          <div className="spinner"></div>
        </div>
      ) : (
        ""
      )}
      {url && urlType === "YouTube" && (
        <iframe width="100%" height="315" src={url} allowFullScreen></iframe>
      )}

      {url && urlType === "Twitter" && (
        <>
          <TwitterTweetEmbed tweetId={url} />
        </>
      )}

      {url && urlType === "iframe" && (
        <div>
          <div dangerouslySetInnerHTML={{ __html: url }} />
        </div>
      )}
      {url && urlType === "instagram" && (
        <iframe
          src={url}
          title="Instagram Post"
          width="400"
          height="480"
          allowtransparency="true"
          allow="encrypted-media"
        ></iframe>
      )}

      {url &&
        (urlType === "YouTube" ||
          urlType === "Twitter" ||
          urlType === "iframe") && (
          <button
            className={`btn btn-primary`}
            onClick={(e) => {
              e?.preventDefault();
              handleSubCardChange(cardIndex, subCardIndex, "actionUrl", "");
            }}
          >
            Remove
          </button>
        )}

        {/* <iframe
          src={`https://www.linkedin.com/posts/deep-shikha-panday-21775a284_activity-7147480827591860224-c_Fl?utm_source=share&utm_medium=member_desktop`}
          title="Linkedin Post"
          width="400"
          height="480"
          allowtransparency="true"
          allow="encrypted-media"
        ></iframe> */}
    </div>
  );
};

export default LinkPreview;
