// import '../../assets/assets/';

import { useEffect, useState } from "react";
import { redirect } from "react-router-dom";

const Dashboard = () => {
  const [isHide, setIsHide] = useState(false);


  return (
    <>
      {isHide ? (
        <section className="content">
          <div className="row">
            <div className="col-xl-3 col-md-6 col-12">
              <div className="info-box">
                <span className="info-box-icon bg-blue">
                  <i className="fa fa-building"></i>
                </span>

                <div className="info-box-content">
                  <span className="info-box-number">110</span>
                  <span className="info-box-text">On Going Projects</span>
                </div>
                {/* <!-- /.info-box-content --> */}
              </div>
              {/* <!-- /.info-box --> */}
            </div>
            {/* <!-- /.col --> */}
            <div className="col-xl-3 col-md-6 col-12">
              <div className="info-box">
                <span className="info-box-icon bg-success">
                  <i className="fa fa-shield"></i>
                </span>

                <div className="info-box-content">
                  <span className="info-box-number">250</span>
                  <span className="info-box-text">Complate Projects</span>
                </div>
                {/* <!-- /.info-box-content --> */}
              </div>
              {/* <!-- /.info-box --> */}
            </div>
            {/* <!-- /.col --> */}

            {/* <!-- fix for small devices only --> */}
            <div className="clearfix visible-sm-block"></div>

            <div className="col-xl-3 col-md-6 col-12">
              <div className="info-box">
                <span className="info-box-icon bg-purple">
                  <i className="fa fa-hospital-o"></i>
                </span>

                <div className="info-box-content">
                  <span className="info-box-number">105</span>
                  <span className="info-box-text">Commercial Projects</span>
                </div>
                {/* <!-- /.info-box-content --> */}
              </div>
              {/* <!-- /.info-box --> */}
            </div>
            {/* <!-- /.col --> */}
            <div className="col-xl-3 col-md-6 col-12">
              <div className="info-box">
                <span className="info-box-icon bg-red">
                  <i className="fa fa-home"></i>
                </span>

                <div className="info-box-content">
                  <span className="info-box-number">145</span>
                  <span className="info-box-text">Residential Projects</span>
                </div>
                {/* <!-- /.info-box-content --> */}
              </div>
              {/* <!-- /.info-box --> */}
            </div>
            {/* <!-- /.col --> */}
          </div>

          <div className="row">
            <div className="col-lg-4 col-12">
              {/* <!-- Doughnut CHART --> */}
              <div className="box">
                <div className="box-header with-border">
                  <h5 className="box-title">Daily Inquery</h5>

                  <ul className="box-controls pull-right">
                    <li>
                      <a className="box-btn-close" href="#"></a>
                    </li>
                    <li>
                      <a className="box-btn-slide" href="#"></a>
                    </li>
                    <li>
                      <a className="box-btn-fullscreen" href="#"></a>
                    </li>
                  </ul>
                </div>
                <div className="box-body">
                  <div className="box-body chart-responsive">
                    <div
                      className="chart"
                      id="daily-inquery"
                      style={{ height: "310px" }}
                    ></div>
                  </div>
                </div>
                {/* <!-- /.box-body --> */}
              </div>
              {/* <!-- /.box --> */}
            </div>

            <div className="col-lg-4 col-12">
              {/* <!-- Doughnut CHART --> */}
              <div className="box">
                <div className="box-header with-border">
                  <h4 className="box-title">Booking Status</h4>

                  <ul className="box-controls pull-right">
                    <li>
                      <a className="box-btn-close" href="#"></a>
                    </li>
                    <li>
                      <a className="box-btn-slide" href="#"></a>
                    </li>
                    <li>
                      <a className="box-btn-fullscreen" href="#"></a>
                    </li>
                  </ul>
                </div>
                <div className="box-body">
                  <ul className="list-inline text-right">
                    <li>
                      <h5>
                        <i className="fa fa-circle mr-5 text-info"></i>Inquery
                      </h5>
                    </li>
                    <li>
                      <h5>
                        <i className="fa fa-circle mr-5 text-danger"></i>Conform
                      </h5>
                    </li>
                  </ul>
                  <div
                    className="chart"
                    id="bar-chart"
                    style={{ height: "300px" }}
                  ></div>
                </div>
                {/* <!-- /.box-body --> */}
              </div>
              {/* <!-- /.box --> */}
            </div>

            <div className="col-lg-4 col-12">
              {/* <!-- AREA CHART --> */}
              <div className="box">
                <div className="box-header with-border">
                  <h4 className="box-title">Revenue Statistics</h4>

                  <ul className="box-controls pull-right">
                    <li>
                      <a className="box-btn-close" href="#"></a>
                    </li>
                    <li>
                      <a className="box-btn-slide" href="#"></a>
                    </li>
                    <li>
                      <a className="box-btn-fullscreen" href="#"></a>
                    </li>
                  </ul>
                </div>
                <div className="box-body chart-responsive">
                  <ul className="list-inline text-right">
                    <li>
                      <h5>
                        <i className="fa fa-circle mr-5 text-info"></i>
                        Commercial
                      </h5>
                    </li>
                    <li>
                      <h5>
                        <i className="fa fa-circle mr-5 text-success"></i>
                        Residential
                      </h5>
                    </li>
                  </ul>
                  <div
                    className="chart"
                    id="revenue-chart"
                    style={{ height: "300px" }}
                  ></div>
                </div>
                {/* <!-- /.box-body --> */}
              </div>
              {/* <!-- /.box --> */}
            </div>

            <div className="col-lg-6 col-12">
              {/* <!-- AREA CHART --> */}
              <div className="box">
                <div className="box-header with-border">
                  <h4 className="box-title">Resent Inquery</h4>

                  <ul className="box-controls pull-right">
                    <li>
                      <a className="box-btn-close" href="#"></a>
                    </li>
                    <li>
                      <a className="box-btn-slide" href="#"></a>
                    </li>
                    <li>
                      <a className="box-btn-fullscreen" href="#"></a>
                    </li>
                  </ul>
                </div>
                <div className="box-body">
                  <div className="table-responsive">
                    <table className="table">
                      <tbody>
                        <tr>
                          <th>#</th>
                          <th>Name</th>
                          <th>Property Type</th>
                          <th>Date</th>
                          <th>Status</th>
                          <th>Contact Number</th>
                        </tr>
                        <tr>
                          <td>1.</td>
                          <td>Jacob</td>
                          <td>
                            <span className="badge bg-danger">Commercial</span>
                          </td>
                          <td>2019-Apr-04</td>
                          <td>
                            <span className="badge bg-info">Open</span>
                          </td>
                          <td>124 548 1254</td>
                        </tr>
                        <tr>
                          <td>2.</td>
                          <td>William</td>
                          <td>
                            <span className="badge bg-success">
                              Residential
                            </span>
                          </td>
                          <td>2019-Apr-15</td>
                          <td>
                            <span className="badge bg-info">Open</span>
                          </td>
                          <td>845 548 1254</td>
                        </tr>
                        <tr>
                          <td>3.</td>
                          <td>Jayden</td>
                          <td>
                            <span className="badge bg-danger">Commercial</span>
                          </td>
                          <td>2019-Apr-18</td>
                          <td>
                            <span className="badge bg-info">Open</span>
                          </td>
                          <td>568 965 1254</td>
                        </tr>
                        <tr>
                          <td>4.</td>
                          <td>Michael</td>
                          <td>
                            <span className="badge bg-danger">Commercial</span>
                          </td>
                          <td>2019-Apr-22</td>
                          <td>
                            <span className="badge bg-warning">On Hold</span>
                          </td>
                          <td>965 854 1254</td>
                        </tr>
                        <tr>
                          <td>5.</td>
                          <td>Alexander</td>
                          <td>
                            <span className="badge bg-success">
                              Residential
                            </span>
                          </td>
                          <td>2019-Apr-04</td>
                          <td>
                            <span className="badge bg-info">Open</span>
                          </td>
                          <td>632 987 1254</td>
                        </tr>
                        <tr>
                          <td>6.</td>
                          <td>Anthony</td>
                          <td>
                            <span className="badge bg-success">
                              Residential
                            </span>
                          </td>
                          <td>2019-Apr-04</td>
                          <td>
                            <span className="badge bg-info">Open</span>
                          </td>
                          <td>321 456 1254</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* <!-- /.box-body --> */}
              </div>
              {/* <!-- /.box --> */}
            </div>

            <div className="col-lg-6 col-12">
              {/* <!-- AREA CHART --> */}
              <div className="box">
                <div className="box-header with-border">
                  <h4 className="box-title">Project Sales</h4>

                  <ul className="box-controls pull-right">
                    <li>
                      <a className="box-btn-close" href="#"></a>
                    </li>
                    <li>
                      <a className="box-btn-slide" href="#"></a>
                    </li>
                    <li>
                      <a className="box-btn-fullscreen" href="#"></a>
                    </li>
                  </ul>
                </div>
                <div className="box-body">
                  <div className="table-responsive">
                    <table className="table">
                      <tbody>
                        <tr>
                          <th>#</th>
                          <th>Project Name</th>
                          <th>Property Type</th>
                          <th>Sold Unite</th>
                          <th>Unsold Unite</th>
                          <th>Totle Unite</th>
                        </tr>
                        <tr>
                          <td>1.</td>
                          <td>Ipsum-zlm Tower</td>
                          <td>
                            <span className="badge bg-danger">Residential</span>
                          </td>
                          <td>85</td>
                          <td>41</td>
                          <td>126</td>
                        </tr>
                        <tr>
                          <td>2.</td>
                          <td>Monek-pro Villa</td>
                          <td>
                            <span className="badge bg-success">
                              Residential
                            </span>
                          </td>
                          <td>75</td>
                          <td>65</td>
                          <td>140</td>
                        </tr>
                        <tr>
                          <td>3.</td>
                          <td>Zila-pro Complex</td>
                          <td>
                            <span className="badge bg-danger">Commercial</span>
                          </td>
                          <td>98</td>
                          <td>45</td>
                          <td>143</td>
                        </tr>
                        <tr>
                          <td>4.</td>
                          <td>Akil-xlm Tower</td>
                          <td>
                            <span className="badge bg-danger">Residential</span>
                          </td>
                          <td>96</td>
                          <td>45</td>
                          <td>141</td>
                        </tr>
                        <tr>
                          <td>5.</td>
                          <td>Duler-vista Villa</td>
                          <td>
                            <span className="badge bg-success">
                              Residential
                            </span>
                          </td>
                          <td>154</td>
                          <td>85</td>
                          <td>239</td>
                        </tr>
                        <tr>
                          <td>6.</td>
                          <td>Willsbro Complex</td>
                          <td>
                            <span className="badge bg-success">Commercial</span>
                          </td>
                          <td>124</td>
                          <td>58</td>
                          <td>182</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* <!-- /.box-body --> */}
              </div>
              {/* <!-- /.box --> */}
            </div>

            <div className="col-12 col-lg-8">
              {/* <!-- quick email widget --> */}
              <div className="box">
                <div className="box-header">
                  <i className="fa fa-envelope"></i>
                  <h4 className="box-title">Quick Email</h4>
                  <ul className="box-controls pull-right">
                    <li>
                      <a className="box-btn-close" href="#"></a>
                    </li>
                    <li>
                      <a className="box-btn-slide" href="#"></a>
                    </li>
                    <li>
                      <a className="box-btn-fullscreen" href="#"></a>
                    </li>
                  </ul>
                </div>
                <div className="box-body">
                  <form action="#" method="post">
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        name="emailto"
                        placeholder="Email to:"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="subject"
                        placeholder="Subject"
                      />
                    </div>
                    <div>
                      <textarea
                        className="textarea b-1 p-10 w-p100"
                        placeholder="Message"
                      ></textarea>
                    </div>
                  </form>
                </div>
                <div className="box-footer clearfix">
                  <button
                    type="button"
                    className="pull-right btn btn-blue"
                    id="sendEmail"
                  >
                    {" "}
                    Send <i className="fa fa-paper-plane-o"></i>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="box">
                <div className="box-header with-border">
                  <h4 className="box-title">Calendar</h4>

                  <ul className="box-controls pull-right">
                    <li className="dropdown">
                      <a data-toggle="dropdown" href="#" aria-expanded="false">
                        <i className="ti-more-alt rotate-90"></i>
                      </a>
                      <div
                        className="dropdown-menu dropdown-menu-right"
                        x-placement="bottom-end"
                        style={{
                          position: "absolute",
                          transform: "translate3d(-114px, 21px, 0px)",
                          top: "0px",
                          left: "0px",
                          willChange: "transform",
                        }}
                      >
                        <a className="dropdown-item" href="#">
                          <i className="ti-import"></i> Import
                        </a>
                        <a className="dropdown-item" href="#">
                          <i className="ti-export"></i> Export
                        </a>
                        <a className="dropdown-item" href="#">
                          <i className="ti-printer"></i> Print
                        </a>
                        <div className="dropdown-divider"></div>
                        <a className="dropdown-item" href="#">
                          <i className="ti-settings"></i> Settings
                        </a>
                      </div>
                    </li>
                    <li>
                      <a className="box-btn-close" href="#"></a>
                    </li>
                    <li>
                      <a className="box-btn-slide" href="#"></a>
                    </li>
                    <li>
                      <a className="box-btn-fullscreen" href="#"></a>
                    </li>
                  </ul>
                </div>
                <div className="box-body p-0">
                  {/* <!-- THE CALENDAR --> */}
                  <div id="calendar" className="dask"></div>
                </div>
                {/* <!-- /.box-body --> */}
              </div>
              {/* <!-- /.box --> */}
            </div>
          </div>
          {/* <!-- /.row --> */}
        </section>
      ) : (
        ""
      )}
    </>
  );
};

export default Dashboard;
