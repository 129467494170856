import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import config from "../../../../config";
import Swal from "sweetalert2";
import { toast } from "react-toastify"; // Import react-toastify
import { useForm } from "react-hook-form";
// import React from "react";
import { apiEndpoints } from "../../../common/api/apiEndpoints";

import { convertToSlug } from "../../../common/utils/convertToSlug";
import _ from "lodash";
import { GetListApi, CreateDataApi } from "../../../common/api/api";
import moment from "moment";

const useSectionHook = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm();

  const [sectionList, setSectionList] = useState();
  const [sectionItem, setSectionItem] = useState();
  const handleClose = (e) => setShow(false);
  const [show, setShow] = useState(false);
  // const [allowedStates, setallowedStates] = useState(allowedState);

  const [isOpenCanvas, setIsOpenCanvas] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const userData = sessionStorage.getItem("user");
  const user = userData ? JSON.parse(userData) : null;

  const [sectionView, setSectionView] = useState([]);

  // section descriptiton start from here

  const fetchSectionById = async () => {
    try {
      axios
        .get(`${config.apiUrl}/section/${id}`)
        .then((response) => {
          if (response?.data) {
            setSectionView(response?.data);
            let length = response?.data.parent_section.length - 1;
            setValue("parent_section", response?.data.parent_section[length]);
          }
        })
        .catch((error) => {});
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchSectionById();
    }
  }, [id]);

  const generateSlug = (e) => {
    let slug = convertToSlug(e?.target?.value);
    setValue("slug", slug);
  };

  const onSubmit = (data) => {
    data.created_by = user?.name;
    data.created_on = moment().format("MMMM,DD MM YYYY | hh:mm:ss a");

    axios
      .post(`${config.apiUrl}/${apiEndpoints?.scriptTag}`, data)
      .then((response) => {
        setIsOpenCanvas(false);
        if (response.data) {
          fetchOurSection();

          setShow(false);
          Swal.fire({
            icon: "success",
            title: "Script Tag added successfully.",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      })
      .catch((error) => {
        console.error("Error adding script tag:", error);
      });
  };

  const onUpdate = async (data) => {
    data.updated_by = user?.name;
    data.updated_on = moment().format("MMMM,DD MM YYYY | hh:mm:ss a");
    await axios
      .patch(
        `${config.apiUrl}/${apiEndpoints?.scriptTagById(sectionItem?._id)}`,
        data
      )
      .then((response) => {
        if (response?.data) {
          fetchOurSection();
          setIsOpenCanvas(false);
          setShow(false);
          Swal.fire({
            icon: "success",
            title: "Script Tag updated successfully.",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      })
      .catch((error) => {
        console.error("Error adding section:", error);
      });
  };

  useEffect(() => {
    fetchOurSection();
  }, [currentPage]);

  const fetchOurSection = async () => {
    const response = await GetListApi(`${apiEndpoints.scriptTag}`, {
      page: currentPage,
      pageSize: 20,
      pagination: true,
    });
    if (response) {
      setSectionList(response?.scriptTags);
      setTotalPages(response?.pages);
    }
  };

  const handleDelete = (id) => {
    // Show the confirm dialog
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        // If the user confirms, proceed with deletion
        axios
          .delete(`${config.apiUrl}/${apiEndpoints.scriptTagById(id)}`)
          .then((response) => {
            fetchOurSection();
            Swal.fire({
              title: "Item deleted successfully",
              icon: "success",
            });
          })
          .catch((error) => {
            console.error("Error deleting item:", error);
            // Handle error, show error message, etc.
            toast.error("An error occurred while deleting");
          });
      }
    });
  };
  const onSectionListUpdate = async (data) => {
    try {
      const userData = sessionStorage.getItem("user");
      const user = userData ? JSON.parse(userData) : null;
      let dt = new Date();
      data.updated_by = user?.name;
      data.updated_on = dt;

      const response = await axios
        .put(`${config.apiUrl}/${apiEndpoints?.scriptTag}`, {
          draggedFrom: data?.draggedFrom,
          draggedTo: data?.draggedTo,
          originalOrder: data?.originalOrder,
        })
        .then((response) => {
          if (response.data) {
            fetchOurSection();
            Swal.fire({
              title: "Item Updated successfully",
              icon: "success",
            });
          } else {
            toast.error(response.data.error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };
  return {
    onSubmit,
    onUpdate,
    navigate,
    register,
    handleSubmit,
    sectionView,
    setSectionView,
    errors,
    handleDelete,
    sectionList,
    setSectionList,
    sectionItem,
    setSectionItem,
    setValue,
    isOpenCanvas,
    setIsOpenCanvas,
    onUpdate,
    fetchOurSection,
    onSectionListUpdate,
    currentPage,
    totalPages,
    setCurrentPage,
    handleClose,
    show,
    setShow,
    getValues,
    generateSlug,
  };
};

export default useSectionHook;
