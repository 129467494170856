import { Link } from "react-router-dom";
import axios from "axios";
import React, { useState, useEffect } from "react";
import config from "../../../config";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify"; // Import react-toastify
import Common from "../../common/Common";
import { ConvertArrayTOString } from "../../common/utils/arrayToString";
import useOpinionPollHook from "./hook";
import { CheckAccess } from "../../common/utils/roleAccess";

const OpinonPolls = () => {
  const {
    handleDelete,
    opinionPolls, 
    setOpinionPolls,
    imgUrl, 
    setImgUrl
  } = useOpinionPollHook()
 
  return (
    <>
      <div className="content">
        <div className="card col-md-12 m-auto p-30">
          <div className="card-header">
            <h3 className="text-bold">Opinion Polls</h3>
           {CheckAccess("opinion poll", "create") &&  <div className="timeline-footer text-right">
              <Link
                to="/opinion-polls/create"
                className="btn btn-primary btn-round btn-sm"
              >
                Add A Poll
              </Link>
            </div>}
          </div>
          <div className="card-body">
            <div className="">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Topic</th>
                    <th>Options</th>
                    <th>Voting</th>
                    <th>View Result</th>
                    <th>Allow Change</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {opinionPolls?.length > 0 &&
                    opinionPolls?.map((data, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            <div className={`d-flex align-items-center`}>
                              <div>
                                <img
                                  src={`${config?.apiUrl}/${data?.hero_image?.file_path}`}
                                  alt=""
                                  width={40}
                                  height={40}
                                  className="rounded-circle m-2"
                                />
                              </div>
                              <div>
                                <div className={`fw-normal`}>
                                  {data?.topic}
                                </div>
                              </div>
                            </div>
                          </td>
                          {/* <td>{data?.options}</td> */}
                          <td>
                            {data?.options?.length > 0
                              ? ConvertArrayTOString(
                                  data?.options,
                                  "opinionPoll"
                                )
                              : `-`}
                          </td>
                          <td>{data?.voting}</td>
                          <td>{data?.view_result ? "Yes" : "No"}</td>
                          <td>{data?.allow_change_vote ? "Yes" : "No"}</td>
                          <td>
                           {CheckAccess("opinion poll", "update") &&  <Link
                              to={`/opinion-polls/update/${data._id}`}
                              className="mr-2"
                            >
                              <i className="fa fa-edit"></i>
                            </Link>}
                            {CheckAccess("opinion poll", "delete") && <Link
                              onClick={() => handleDelete(data._id)}
                              className="mr-2"
                            >
                              <i className="fa fa-trash"></i>
                            </Link>}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OpinonPolls;
