import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
// import config from "../../../config";
import $ from "jquery";
import { apiEndpoints } from "../../../common/api/apiEndpoints";
import Swal from "sweetalert2";
import config from './../../../../config';
import {
  CreateDataApi,
  DeleteApi,
  GetListApi,
  UpdateDataApi,
} from "../../../common/api/api";

const useUserHook = () => {
  const [userList, setUserList] = useState([]);
  const [editUser, setEditUser] = useState({});
  const [isOpenCanvas, setIsOpenCanvas] = useState(false);
  const pageTitle = "User";
  const OffcanvasTitle = "Create User ";
  const navigate = useNavigate();
  const userData = localStorage.getItem("user");
  const user = userData ? JSON.parse(userData) : null;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const onSubmit = async (data) => {
    data.created_by = user.name;
    data.updated_by = user.name;
    data.name = `${data?.Fname} `;
    // axios
    //   .post(`${config.apiUrl}/${apiEndpoints.createUser}`, data)
    //   .then((response) => {
    //     fetchUserList();
    //     toast.success("User added successfully.");
    //     setIsOpenCanvas(false);
    //   })
    //   .catch((error) => {
    //     console.error("Error User:", error);
    //   });
    data.name = `${data?.Fname} ${data?.Lname}`;
    const response = await CreateDataApi(apiEndpoints?.createUser, data);
    if (response) {
      fetchUserList();
      toast.success("User added successfully.");
      setIsOpenCanvas(false);
    }
  };
  const onUserUpdate = async (data) => {
    try {
      data.created_by = user.name;
      data.updated_by = user.name;
      data.name = `${data?.Fname} ${data?.Lname}`;
      const response = await UpdateDataApi(apiEndpoints?.userById(editUser?._id),data);
      if (response) {
        setIsOpenCanvas(false);
        setEditUser({});
        fetchUserList();
        toast.success("User updated successfully.");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchUserList = async (name, mobile, email, user_role,status) => {
    let params = {
      name: name,
      // name:name,
      mobile: mobile,
      email: email,
      user_role: user_role,
      page: currentPage,
      status:status,
      pageSize: 20
    };
    const user = await GetListApi("user", params);
    setUserList(user?.users);
    setTotalPages(user?.pages);
  };

  useEffect(() => {
    fetchUserList();
  }, [currentPage]);
  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await DeleteApi(apiEndpoints?.userById(id));
        if (response) {
          fetchUserList();
          toast.success("User deleted successfully");
        }
      }
    });
  };
  return {
    onSubmit,
    userList,
    setUserList,
    pageTitle,
    OffcanvasTitle,
    navigate,
    register,
    handleSubmit,
    errors,
    setValue,
    handleDelete,
    isOpenCanvas,
    setIsOpenCanvas,
    editUser,
    setEditUser,
    onUserUpdate,
    fetchUserList,
    currentPage,
    setCurrentPage,
    totalPages,
  };
};

export default useUserHook;
