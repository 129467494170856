import React, { useState } from "react";
import { Button, Offcanvas } from "react-bootstrap";
import ContentForm from "./stepperForm/contentForm";
import RulesForm from "./stepperForm/rulesForm";
import { Row, Col } from "react-bootstrap";
import useStoreHook from "../hooks/store.hook";
import useCollectionHook from "../collectionHook";

const CollectionModal = ({ ...props }) => {
  const { handleClose, show } = props;
  const [isTab, setIsTab] = useState("CONTENT");
  const Tab_Navigation = ["CONTENT", "RULE"];
  const {
    navigate,
    register,
    handleSubmit,
    handleChange,
    rulesType,
    setRulesType,
    user,
    onSubmit,
    HandleSelectChange,
    tagsList,
    setTagsList,
    selectedTagsList,
    setSelectedTagsList,
    storyTemplate,
    setStoryTemplate,
    selectedStoryTemplate,
    setSelectedStoryTemplate,
    errors,
    includeSectionList,
    setIncludeSectionList,
    selectedIncludeSection,
    setSelectedIncludeSection,
    excludeSectionList,
    setExcludeSectionList,
    selectedExcludeSection,
    setSelectedExcludeSection,
    id,
    onUpdate,
    authorList,
    selectedAuthorList,
    sortedByList,
    selectedSortedBy,
    stepperForm,
    setStepperForm,
    HandleUploadImage,
    imgUrl,
    setImgUrl,
    isAnyFieldFilled,
    setIsAnyFieldFilled,
    inputs,
    setInputs,
    HandleErrorChange,
    HandleNext,
    contentFormErr,
  } = useCollectionHook();

  return (
    <Offcanvas show={show} onHide={handleClose} {...props} placement={"end"}>
      <form onSubmit={handleSubmit(onUpdate)}>
        <div className="py-30 px-20">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex gap-2 align-items-center">
              <i
                className="fa fa-close fs-4 cursor-pointer"
                onClick={handleClose}
              />
              <h4 className="p-0 m-0">Setting</h4>
            </div>
            <Button
              className="btn btn-primary btn-round shadow-none rounded-1 collection-tab"
              type="submit"
            >
              Update
            </Button>
          </div>
          <Offcanvas.Body>
            <div className="overflow-x-hidden">
              <Row className="g-0">
                {Tab_Navigation.map((item, index) => {
                  return (
                    <Col md={6} xs={6} className="w-100" key={index}>
                      <div
                        className={`${
                          isTab === item
                            ? "collection-border collection-text text-center"
                            : " text-center"
                        }  text-bolder mb-0 `}
                        role="button"
                        onClick={(e) => {
                          setIsTab(item);
                        }}
                      >
                        {item}
                      </div>
                    </Col>
                  );
                })}
              </Row>
              <div>
                {isTab === "CONTENT" && (
                  <ContentForm
                    setStepperForm={setStepperForm}
                    navigate={navigate}
                    register={register}
                    handleSubmit={handleSubmit}
                    handleChange={handleChange}
                    rulesType={rulesType}
                    setRulesType={setRulesType}
                    user={user}
                    HandleUploadImage={HandleUploadImage}
                    imgUrl={imgUrl}
                    setImgUrl={setImgUrl}
                    errors={errors}
                    inputs={inputs}
                    setInputs={setInputs}
                    HandleErrorChange={HandleErrorChange}
                    HandleNext={HandleNext}
                    contentFormErr={contentFormErr}
                  />
                )}

                {isTab === "RULE" && (
                  <RulesForm
                    setStepperForm={setStepperForm}
                    navigate={navigate}
                    errors={errors}
                    register={register}
                    handleSubmit={handleSubmit}
                    handleChange={handleChange}
                    rulesType={rulesType}
                    setRulesType={setRulesType}
                    user={user}
                    onSubmit={onSubmit}
                    HandleSelectChange={HandleSelectChange}
                    tagsList={tagsList}
                    setTagsList={setTagsList}
                    selectedTagsList={selectedTagsList}
                    setSelectedTagsList={setSelectedTagsList}
                    storyTemplate={storyTemplate}
                    setStoryTemplate={setStoryTemplate}
                    selectedStoryTemplate={selectedStoryTemplate}
                    setSelectedStoryTemplate={setSelectedStoryTemplate}
                    includeSectionList={includeSectionList}
                    setIncludeSectionList={setIncludeSectionList}
                    selectedIncludeSection={selectedIncludeSection}
                    setSelectedIncludeSection={setSelectedIncludeSection}
                    excludeSectionList={excludeSectionList}
                    setExcludeSectionList={setExcludeSectionList}
                    selectedExcludeSection={selectedExcludeSection}
                    setSelectedExcludeSection={setSelectedExcludeSection}
                    id={id}
                    authorList={authorList}
                    selectedAuthorList={selectedAuthorList}
                    sortedByList={sortedByList}
                    selectedSortedBy={selectedSortedBy}
                    isAnyFieldFilled={isAnyFieldFilled}
                    setIsAnyFieldFilled={setIsAnyFieldFilled}
                  />
                )}
              </div>
            </div>
          </Offcanvas.Body>
        </div>
      </form>
    </Offcanvas>
  );
};

export default CollectionModal;
