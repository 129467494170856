import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import config from "../../../config";

const Contacts = () => {
  const [mySettings, setMySettings] = useState([]);

  useEffect(() => {
    fetchSettings();
  }, [])

  const fetchSettings = () => {
    axios.get(`${config.apiUrl}/settings?type=contact`)
      .then(response => {
        setMySettings(response.data);
      })
      .catch(error => {
        console.log("Error fetching settings: ", error);
      });
  }

  return (
    <>
      <div className="content">
        <div className="content">
          <div className="card col-md-5 m-auto p-30">
            <div className="card-header">
              <h3 className="text-bold">Contacts</h3>
            </div>
            <div className="card-body">
              <div className="responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Contact Type</th>
                      <th>Value</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {mySettings.map((request, index) => (
                      < tr >
                        <td>{index + 1}</td>
                        <td>{request.name}</td>
                        <td>{request.value}</td>
                        <td>
                          <a href="#">
                            <i className="fa fa-edit"></i>
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  );
}

export default Contacts;