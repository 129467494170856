import axios from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import config from "../../../../config";
import { toast } from "react-toastify";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { apiEndpoints } from "../../../common/api/apiEndpoints";
import { GetListApi } from "../../../common/api/api";

const useRolesHook = () => {
  const [rolesList, setRolesList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const navigate = useNavigate();
  const { id } = useParams();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  useEffect(() => {
    fetchRolesList();
  }, [currentPage]);

  const fetchRolesList = async (name,status) => {
    const roles = await GetListApi(`${apiEndpoints?.roles}`, {
      name:name,
      status:status,
      page: currentPage,
      pageSize: 20,
      pagination:true
    });
    if (roles?.roles?.length) {
      setIsLoading(false);
      setRolesList(roles?.roles);
      setTotalPages(roles?.pages);
    }
  };
  
  const onSubmitRoles = async (data) => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        `${config.apiUrl}/${apiEndpoints?.roles}`,
        data
      );
      const responseData = response.data;
      if (responseData) {
        setIsLoading(false);
        toast.success("Roles created successfully.");
        navigate("/user-management/roles");
      } else {
        toast.error(response.data.error);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const onUpdateRoles = async (data) => {
    try {
      const response = await axios.put(
        `${config.apiUrl}/${apiEndpoints?.roles}/${id}`,
        data
      );
      const responseData = response.data;
      if (responseData) {
        setIsLoading(false);
        toast.success("Roles updated successfully.");
        navigate("/user-management/roles");
      } else {
        toast.error(response.data.error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${config.apiUrl}/${apiEndpoints?.rolesById(id)}`)
          .then((response) => {
            toast.success(`Roles deleted successfully.`);
            console.log("Roles deleted successfully:", response.data);
            fetchRolesList();
          })
          .catch((error) => {
            console.error("Error deleting item:", error);
            toast.error("An error occurred while deleting");
          });
      }
    });
  };

  return {
    id,
    rolesList,
    setRolesList,
    handleDelete,
    onSubmitRoles,
    onUpdateRoles,
    register,
    handleSubmit,
    errors,
    setValue,
    isLoading,
    setIsLoading,
    currentPage,
    setCurrentPage,
    totalPages,
    fetchRolesList
  };
};

export default useRolesHook;
