import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import usePermissionHook from "./permissionHook";
import $ from "jquery";
import { Badge } from "react-bootstrap";
import ReactSwitch from "react-switch";
import { CheckAccess } from "../../../common/utils/roleAccess";
import Pagination from "../../../common/pagination";

const ListPermissions = () => {
  const {
    listPermission,
    handleDelete,
    editPermission,
    setEditPermission,
    HandleEdit,
    fetchPermissionList,
    totalPages,
    currentPage,
    handlePageChange,
  } = usePermissionHook();
  const [loading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, []);

  return (
    <>
      <div className="content">
        <div className="content">
          <div className="card col-md-12 m-auto p-30">
            <div className="card-header">
              <h3 className="text-bold">Permissions</h3>
              {CheckAccess("permissions", "create") && <div className="timeline-footer text-right">
                <Link
                  to="/user-management/permissions/create"
                  className="btn btn-primary btn-round btn-sm"
                >
                  Add Permissions
                </Link>
              </div>}
            </div>
            <div className="card-body">
              <div className="">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Permission Group</th>
                      <th>Name</th>
                      <th>Controller Name</th>
                      <th>Action Name</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr style={{ height: "50px" }}>
                      <th scope="col"></th>
                      <th scope="col">
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => {
                            fetchPermissionList(e.target.value, "", "", "", "");
                          }}
                          data-table="your-table"
                          placeholder="Item to search.."
                        />
                      </th>
                      <th scope="col">
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => {
                            fetchPermissionList("", e.target.value, "", "", "");
                          }}
                          data-table="your-table"
                          placeholder="Item to search.."
                        />
                      </th>
                      <th scope="col">
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => {
                            fetchPermissionList("", "", e.target.value, "", "");
                          }}
                          data-table="your-table"
                          placeholder="Item to search.."
                        />
                      </th>
                      <th scope="col">
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => {
                            fetchPermissionList("", "", "", e.target.value, "");
                          }}
                          data-table="your-table"
                          placeholder="Item to search.."
                        />
                      </th>
                      <th scope="col">
                        <select
                          name="status"
                          className="form-control"
                          onChange={(e) => {
                            fetchPermissionList("", "", "", "", e.target.value);
                          }}
                        >
                          <option value={""}>All</option>
                          <option value={"Active"}>Active</option>
                          <option value={"Inactive"}>Inactive</option>
                        </select>
                      </th>
                    </tr>
                    {!listPermission?.length > 0 && loading ? (
                      <tr>
                        <div id="loader">
                          <div class="d-flex flex-column align-items-center">
                            <div class="spinner-grow text-gold"></div>
                            <span class="text-gold mt-1">Loading...</span>
                          </div>
                        </div>
                      </tr>
                    ) : (
                      listPermission?.length > 0 &&
                      listPermission?.map((item, key) => {
                        return (
                          <tr key={key}>
                            <td>{key + 1}</td>
                            <td>{item.permissionGroup?.name || `-`}</td>
                            <td>{item.name}</td>
                            <td>{item.controllerName}</td>
                            <td>{item.action_name}</td>
                            <td>
                              <div>
                                <Badge
                                  bg={
                                    item.status === "Active"
                                      ? "success"
                                      : "danger"
                                  }
                                  text="white"
                                >
                                  {item.status}
                                </Badge>
                              </div>
                            </td>
                            <td className={`d-flex`}>
                              {/* <Link to={`/our-works/${item.slug}`} className="mr-2"><i className="fa fa-eye"></i></Link> */}
                            {CheckAccess("permissions", "update") &&   <div
                                role="button"
                                className="mr-2"
                                onClick={() => HandleEdit(item)}
                              >
                                <i className="fa fa-edit"></i>
                              </div>}
                              {/* <Link to={`/user-management/permissions/EditPermissionForm`} className="mr-2"><i className="fa fa-edit"></i></Link> */}
                             {CheckAccess("permissions", "delete") &&  <Link onClick={() => handleDelete(item._id)}>
                                <i className="fa fa-trash"></i>
                              </Link>}
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
                {/* {totalPages ? (
                  <div className={`container`}>
                    <div className={`row`}>
                      <div
                        className={`col-sm-12 d-flex justify-content-center px-0`}
                      >
                        <Pagination
                          currentPage={currentPage}
                          totalPages={totalPages}
                          onPageChange={handlePageChange}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListPermissions;
