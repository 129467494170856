const convertTimestamp = (timestamp) => {
  const dtObject = new Date(timestamp);
  if (!timestamp) {
    return "";
  }

  const formattedDate = dtObject.toLocaleString("en-US", {
    day: "numeric",
    month: "short",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });

  return formattedDate;
};

export { convertTimestamp };
