import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import config from "../../../config";
import axios from "axios";

const SocialLinks = () => {
  const [socialSettings, setSocialSettings] = useState([]);

  useEffect(() => {
    fetchSocial();
  }, [])

  const fetchSocial = () => {
    axios.get(`${config.apiUrl}/settings?type=social_links`)
      .then(response => {
        setSocialSettings(response.data);
      })
      .catch(error => {
        console.log("Error occured in feteching social settings: ", error);
      });
  }

  return (
    <>
      <div className="content">
        <div className="content">
          <div className="card col-md-5 m-auto p-30">
            <div className="card-header">
              <h3 className="text-bold">Social Links</h3>
            </div>
            <div className="card-body">
              <div className="responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Value</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {socialSettings.map((request, index) => (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{request.name}</td>
                        <td>
                          <a href={request.value} target="_blank"><i className={request.icon}></i></a>
                        </td>
                        <td>
                          <Link to={`/settings/social-links/${request._id}`}>
                            <i className="fa fa-edit"></i>
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SocialLinks;