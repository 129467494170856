import React from "react";
import { Trash } from "react-bootstrap-icons";
import { Pencil } from "react-bootstrap-icons";
import { Badge } from "react-bootstrap";
import { Eye } from "react-bootstrap-icons";
import useSectionHook from "./sectionHook";
import Pagination from "../../../common/pagination";

import Offcanvas from "react-bootstrap/Offcanvas";
import { CheckAccess } from "../../../common/utils/roleAccess";
import SectionCreate from "./SectionCreate";
import Loader from "../../../common/utils/loader";
function ScriptTagManage() {
  const pageTitle = "Manage Script Tag";

  const {
    onSubmit,
    onUpdate,
    navigate,
    register,
    handleDelete,
    handleSubmit,
    errors,
    sectionList,
    setSectionList,
    sectionItem,
    setSectionItem,
    setValue,
    isOpenCanvas,
    setIsOpenCanvas,
    parents,
    setParents,
    fetchOurSection,
    sectionOption,
    onSectionListUpdate,
    currentPage,
    setCurrentPage,
    totalPages,
    show,
    setShow,
    handleClose,
    getValues,
    generateSlug
  } = useSectionHook();

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  return (
    <>
      <div className="content">
        <div className="">
          <div className="card col-lg-12">
            <div className="card-header">
              <h3 className="text-bold">{pageTitle}</h3>
              <div className="timeline-footer text-right">
                {CheckAccess("section", "create") && (
                  <button
                    type="button"
                    className="btn btn-primary btn-round btn-sm"
                    role="button"
                    onClick={() => {
                      setShow(true);
                      setSectionItem(null);
                      setValue("status", "Active");
                    }}
                  >
                    Add Script Tag
                  </button>
                )}
                &nbsp;
              </div>
            </div>
            <div className="">
              <table className="table table-bordered text-center ">
                <thead className="">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Type</th>
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>

                <tbody>
                  {!sectionList?.length > 0 ? (
                    <Loader />
                  ) : (
                    sectionList?.map((item, key) => {
                      return (
                        <tr key={key}>
                          <th scope="row" className="p-4">
                            {key + 1}
                          </th>
                          <td className="p-4">{item?.name} </td>
                          <td className="p-4">{item?.type} </td>
                          <td>
                            <div>
                              <Badge
                                bg={
                                  item.status === "Active"
                                    ? "success"
                                    : "danger"
                                }
                                text="white"
                              >
                                {item.status}
                              </Badge>
                            </div>
                          </td>
                          <td>
                            {/* view icon */}
                            {/* <button type="button" className="btn btn ">
                          <Eye color="royalblue" size={16} data-toggle="tooltip" title="View"/>
                          </button> */}
                            {/* <Link to={`/section/${SectionUpdate?._id}`}> */}
                            {CheckAccess("section", "view") &&<button
                              type="button"
                              className="btn btn"
                              onClick={() => {
                                navigate(`/section/${item?._id}`);
                              }}
                            >
                              <Eye
                                color="royalblue"
                                size={16}
                                data-toggle="tooltip"
                                title="View Description"
                              />
                            </button>}
                            {/* </Link> */}

                            {CheckAccess("section", "update") &&<button
                              type="button"
                              onClick={() => {
                                setSectionItem(item);
                                setShow(true);
                              }}
                              className="btn btn"
                            >
                              <Pencil
                                color="royalblue"
                                size={16}
                                data-toggle="tooltip"
                                title="Edit"
                              />
                            </button>}
                            {CheckAccess("section", "delete") &&<button
                              type="button"
                              size={16}
                              className="btn btn"
                              onClick={() => handleDelete(item._id)}
                            >
                              <Trash
                                color="royalblue"
                                size={16}
                                data-toggle="tooltip"
                                title="Delete"
                              />
                            </button>}
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
              {totalPages ? (
                <div className={`container`}>
                  <div className={`row`}>
                    <div
                      className={`col-sm-12 d-flex justify-content-center px-0`}
                    >
                      <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              {/* offcanvas start from here manage part start */}
              <Offcanvas
                // className="story-canvas"
                show={show}
                onHide={handleClose}
                placement="end"
              >
                <SectionCreate
                  onSubmit={onSubmit}
                  onUpdate={onUpdate}
                  register={register}
                  setValue={setValue}
                  sectionItem={sectionItem}
                  handleSubmit={handleSubmit}
                  sectionList={sectionList}
                  errors={errors}
                  show={show}
                  setShow={setShow}
                  generateSlug={generateSlug}
                />
              </Offcanvas>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ScriptTagManage;
