import axios from "axios";
import React, { useState } from "react";
import config from "../../../../config";
import usePermissionHook from "./permissionHook";
import { useEffect } from "react";
import usePermissionGroupHook from "../permissionGroup/permissionGroupHook";
import { apiEndpoints } from "../../../common/api/apiEndpoints";
import Select from "react-select";
import makeAnimated from "react-select/animated";
const animatedComponents = makeAnimated();

const CreatePermission = () => {
  const {
    register,
    handleSubmit,
    errors,
    onSubmit,
    onUpdate,
    editPermission,
    setEditPermission,
    id,
    setValue,
  } = usePermissionHook();

  const [selectedValue, setSelectedValue] = useState(null);
 
  const [listPermissionGroup, setListPermissionGroup] = useState([]);

  const [prmGrpOption, setPrmGrpOption] = useState([{ value: "", label: "" }]);

  const fetchPermissionGroupList = async (text, status) => {
    try {
      const response = await axios.get(
        // console.log(response, "working")
        `${config.apiUrl}/${apiEndpoints?.permissionGroup}`,
        {
          params: {
            name: text,
            status: status,
            pagination: false,
          },
        }
      );
      setListPermissionGroup(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchPermissionListById = async (id) => {
    try {
      const response = await axios.get(
        `${config.apiUrl}/${apiEndpoints?.permissionById(id)}`
      );
      if (response?.data) {
        setSelectedValue([
          {
            label: response.data.permissionGroup?.name,
            value: response.data.permissionGroup?._id,
          },
        ]);
        setEditPermission(response?.data);
        setValue("permissionGroup", selectedValue?.value);
        setValue("name", response.data.name);
        setValue("controllerName", response.data.controllerName);
        setValue("action_name", response.data.action_name);
        setValue("status", response.data.status);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchPermissionGroupList();
  }, []);
  useEffect(() => {
    if (id) {
      fetchPermissionListById(id);
    }
  }, [id]);

  useEffect(() => {
    if (listPermissionGroup?.length > 0) {
      listPermissionGroup?.map((group, index) => {
        prmGrpOption?.push({ label: group?.name, value: group?._id });
      });
    }
  }, [listPermissionGroup]);

  const handlePrmGroupChange = (type, e) => {
    setValue("permissionGroup", e?.value);
    setSelectedValue(e);
  };

  return (
    <>
      <div className="content">
        <div className="content">
          <div className="card col-md-12 m-auto p-30">
            <div className="card-header">
              <h3 className="text-bold">{}</h3>
            </div>
            <div className="card-body">
              <form
                onSubmit={id ? handleSubmit(onUpdate) : handleSubmit(onSubmit)}
              >
                <div className="row mb-15">
                  <label
                    htmlFor="type"
                    className="col-sm-4 col-form-label text-right"
                  >
                    Permission Group
                  </label>
                  <div className="col-sm-3">
                    {/* <select
                      name="permissionGroup"
                      {...register("permissionGroup", { required: true })}
                      className="form-control"
                    >
                      <option value={""}>Please Select</option>
                      {listPermissionGroup?.length > 0 &&
                        listPermissionGroup?.map((item, key) => {
                          return (
                            <option key={key} value={item?.name}>
                              {item?.name}
                            </option>
                          );
                        })}
                    </select> */}
                    {errors.type && (
                      <span className="text-danger">
                        Permission Group is required.
                      </span>
                    )}

                    <Select
                      className={` outline-none ddlAttrValue`}
                      closeMenuOnSelect={true}
                      // name="permissionGroup"
                      components={animatedComponents}
                      options={prmGrpOption}
                      onChange={(e) =>
                        handlePrmGroupChange("permissionGroup", e)
                      }
                      isSearchable={true}
                      isClearable={true}
                      // placeholder={prmGrpOption[0]?.label}
                      value={selectedValue}
                    >
                      {prmGrpOption?.map((item) => {
                        return (
                          <option value={item?.value}>{item?.label}</option>
                        );
                      })}
                    </Select>
                  </div>
                </div>

                <div className="row mb-15">
                  <label
                    htmlFor="txtName"
                    className="col-sm-4 col-form-label text-right"
                  >
                    Name
                  </label>
                  <div className="col-sm-3">
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      placeholder="Name"
                      {...register("name", { required: true })}
                    />
                    {errors.name && (
                      <span className="text-danger">Name is required.</span>
                    )}
                  </div>
                </div>

                <div className="row mb-15">
                  <label
                    htmlFor="txtController"
                    className="col-sm-4 col-form-label text-right"
                  >
                    Controller Name
                  </label>
                  <div className="col-sm-3">
                    <input
                      type="text"
                      className="form-control"
                      name="controllerName"
                      placeholder="Controller Name"
                      {...register("controllerName", { required: true })}
                    />
                    {errors.controllerName && (
                      <span className="text-danger">
                        Controller Name is required.
                      </span>
                    )}
                  </div>
                </div>

                <div className="row mb-15">
                  <label
                    htmlFor="txtAction"
                    className="col-sm-4 col-form-label text-right"
                  >
                    Action Name
                  </label>
                  <div className="col-sm-3">
                    <input
                      type="text"
                      className="form-control"
                      name="action_name"
                      placeholder="Action Name"
                      {...register("action_name", { required: true })}
                    />
                    {errors.action_name && (
                      <span className="text-danger">
                        Action Name is required.
                      </span>
                    )}
                  </div>
                </div>

                <div className="row mb-15">
                  <label
                    htmlFor="status"
                    className="col-sm-4 col-form-label text-right"
                  >
                    Status
                  </label>
                  <div className="col-sm-3">
                    <select
                      name="status"
                      {...register("status", { required: true })}
                      className="form-control"
                      defaultValue={"Active"}
                    >
                      <option value={"Active"}>Active</option>
                      <option value={"Inactive"}>Inactive</option>
                    </select>
                  </div>
                </div>

                <div className="row mb-15">
                  <div className="col-sm-12 text-center">
                    {id ? (
                      <button
                        type="submit"
                        className="btn btn-primary btn-round"
                      >
                        Update Permission
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-primary btn-round"
                      >
                        Create Permission
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CreatePermission;
